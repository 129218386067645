<!-- slider_area_start -->
<div class="slider_area">

    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
            <div *ngFor="let slider of sliders; let index = index"
                [ngClass]="index < 1 ? 'carousel-item active' : 'carousel-item'">
                <div class="single_slider d-flex align-items-center"
                    [ngStyle]="{'background-image': 'url('+ host +''+ slider.Featured_Image.url + ')'}">
                    <div class="container">
                        <div class="row">
                            <div class="d-lg-none">
                                <div class="col-xl-12" style="margin-left: 22px;">
                                    <div class="slider_text ">
                                        <h4 class="title-position">
                                            <span class="subtitle" style="line-height: 9px;margin: 17px 0px 0px;">
                                                <b style="color: #0a0a0a;">{{slider.Title}}</b>
                                            </span>
                                        </h4>
                                        <span class="subtitle" style="line-height: 9px;margin: 17px 0px 0px;">
                                            {{slider.Description}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="d-none d-lg-block">
                                <div class="col-xl-12">
                                    <div class="slider_text ">
                                        <h4 class="title-position">
                                            <span class="title" style="line-height: 9px;margin: 17px 0px 0px;">{{slider.Title}}</span>
                                        </h4>
                                        <span class="subtitle" style="line-height: 9px;margin: 17px 0px 0px;">
                                            {{slider.Description}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</div>
<!-- slider_area_end -->


<!-- welcome_docmed_area_start -->
<div class="welcome_docmed_area">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6">
                <div class="welcome_thumb">
                    
                    <img *ngIf="about1.Featured_Image" src="{{host}}{{about1.Featured_Image.url}}" alt="aboutus" [ngStyle]="{'width':'85%'}">
                    
                    
                </div>
            </div>
            <div class="col-xl-6 col-lg-6">
                <div class="welcome_docmed_info">
                    <h3 style="font-size:20px">Welcome to Owlexa</h3>
                    <h3>{{about1.Title}}</h3>
                    <p>{{about1.Brief_Description}}.</p>
                    <!-- <ul>
                      <li> <i class="flaticon-right"></i> Apartments frequently or motionless. </li>
                      <li> <i class="flaticon-right"></i> Duis aute irure dolor in reprehenderit in voluptate.</li>
                      <li> <i class="flaticon-right"></i> Voluptatem quia voluptas sit aspernatur. </li>
                  </ul> -->
                    <a href="/owlexa/about-owlexa" class="boxed-btn3-white-2">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- welcome_docmed_area_end -->

<!-- Pamflet -->
<div class="our_department_area" style="margin-top: -160px;">
    <div class="container">

        <div class="row">
            <div class="col-xl-12">
                <div class="welcome_docmed_info" style="text-align: center;">
                    <h3>Kabar Hari Ini</h3>
                </div>
            </div>
        </div>

        <div class="row">
            <!--Carousel Wrapper-->
            <div id="multi-item-example" class="carousel slide carousel-multi-item" data-ride="carousel"
                style="width: 100%;">

                <!--Controls-->
                <div class="controls-top">
                    <a class="btn-floating" href="#multi-item-example" data-slide="prev" style="color: #1f1f1f70;"><i
                            class="fa fa-chevron-left"></i></a>
                    <a class="btn-floating" href="#multi-item-example" data-slide="next"
                        style="color: #1f1f1f70;margin-left: 2px;"><i class="fa fa-chevron-right"></i></a>
                </div>
                <!--/.Controls-->
                <!--Slides-->
                <div class="carousel-inner" role="listbox">

                    <div class="carousel-item active">
                        <div class="row">
                            <div class="col-md-4" *ngFor="let news of latestNews; let index = index">
                                <div class="card mb-2">
                                    <img class="card-img-top" src="{{host}}{{news.Featured_Image.url}}" height=200px
                                        alt="Card image cap">
                                    <div class="card-body">
                                        <a class="d-inline-block" href="owlexa/news/{{news.id}}">
                                            <h4>{{news.Title.length > 82 ? news.Title.substr(0, 82) + "..." :
                                                news.Title}}</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row">
                            <div class="col-md-4" *ngFor="let news of latestNews.slice(0,3); let index = index"
                                ng-if="pamflet.length < 6">
                                <div class="card mb-2">
                                    <img class="card-img-top" src="{{host}}{{news.Featured_Image.url}}" height=200px
                                        alt="Card image cap">
                                    <div class="card-body">
                                        <h4>{{news.Title.length > 82 ? news.Title.substr(0, 82) + "..." :
                                            news.Title}}</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4"
                                *ngFor="let news of latestNews.slice(3,latestNews.length); let index = index2"
                                ng-if="pamflet.length >= 6">
                                <div class="card mb-2">
                                    <img class="card-img-top" src="{{host}}{{news.Featured_Image.url}}" height=200px
                                        alt="Card image cap">
                                    <div class="card-body">
                                        <h4>{{news.Title.length > 82 ? news.Title.substr(0, 82) + "..." :
                                            news.Title}}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!--/.Slides-->
            </div>

        </div>
    </div>
</div>

<!-- Pamflet -->

<!-- offers_area_start -->
<div class="our_department_area">
    <div class="container">

        <div class="row">
            <div class="col-xl-12">
                <!-- <div class="section_title text-center mb-55"> -->
                <!-- <h3>Our Products</h3> -->
                <!-- <p>Esteem spirit temper too say adieus who direct esteem. <br>
                      It esteems luckily or picture placing drawing. </p> -->
                <!-- </div> -->
                <div class="welcome_docmed_info" style="text-align: center;">
                    <h3>Produk Kami</h3>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-4 col-md-6 col-lg-4" *ngFor="let service of services">
                <div class="single_department">
                    <div class="department_thumb" *ngIf="service.Featured_Image">
                        <img src="{{host}}{{service.Featured_Image.url}}" alt="Image Products" height="200px">
                    </div>
                    <div class="department_content" style="height: 200px;">
                        <!-- <h3><a href="#">{{service.Title}}</a></h3> -->
                        <h3>{{service.Title.length > 35 ? service.Title.substr(0, 35) + "..." :
                            service.Title}}</h3>
                        <!-- <p>{{service.Small_Description}}</p> -->
                        <p>{{service.Brief_Description.length > 50 ? service.Brief_Description.substr(0, 50) + "..."
                            :
                            service.Brief_Description}}</p>
                        <a href="owlexa/services/{{service.id}}" class="learn_more">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- offers_area_end -->


<!-- Emergency_contact start -->
<div class="Emergency_contact">
    <div class="conatiner-fluid p-0">
        <div class="d-lg-none">
            <div class="row no-gutters" style="
            margin-top: -72px;
            margin-bottom: -18px;
        ">
                <div class="col-xl-12">
                    <div style="height: 100%;"
                        class="single_emergency d-flex align-items-center justify-content-center emergency_bg_1 overlay_skyblue">
                        <div class="info">
                            <h3>Kontak Sales Kami</h3>
                        </div>
                        <div class="info_button">
                            <a href="owlexa/contact" class="boxed-btn3-white">Hubungi Kami</a>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="row no-gutters">
                <div class="col-xl-12">
                    <div style="height: 100%;"
                        class="single_emergency d-flex align-items-center justify-content-center emergency_bg_2 overlay_skyblue">
                        <div class="info">
                            <h3>Call Center Penjaminan 24/7</h3>
                        </div>
                        <div class="info_button">
                            <a href="tel:(021)29-830-400" class="boxed-btn3-white">Hubungi Kami</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="d-none d-lg-block">
            <div class="row no-gutters">
                <div class="col-xl-6">
                    <div style="height: 100%;"
                        class="single_emergency d-flex align-items-center justify-content-center emergency_bg_1 overlay_skyblue">
                        <div class="info">
                            <h3>Kontak Sales Kami</h3>
                        </div>
                        <div class="info_button">
                            <a href="owlexa/contact" class="boxed-btn3-white">Hubungi Kami</a>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div style="height: 100%;"
                        class="single_emergency d-flex align-items-center justify-content-center emergency_bg_2 overlay_skyblue">
                        <div class="info">
                            <h3>Call Center Penjaminan 24/7</h3>
                        </div>
                        <div class="info_button">
                            <a href="tel:(021)29-830-400" class="boxed-btn3-white">Hubungi Kami</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- Emergency_contact end -->

<!-- Modal   -->
<div [style.display]="showModal ? 'block' : 'none'" style="z-index: 950;" class="modal" id="imagemodal" tabindex="-1"
    role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">

    <div class="modal-dialog">
        <div *ngFor="let banner of banners.Image ">
            <div class="modal-content" [ngStyle]="{'background-image': 'url('+ host +''+ banner.url + ')', 
            'height':'500px',
            'background-color': 'transparent',
            'border-color': 'transparent', 
            'background-size': 'contain'}">
                <div class="modal-footer">
                    <button type="button" class="close" mat-button aria-label="Close" data-dismiss="modal"
                        (click)="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>

        <!-- <div class="modal-content modal-style" style="
        background-image: url('assets/img/Purple Bright and Bold Health _ Fitness Sale Instagram Post.gif');">
            <div class="modal-footer">
                <button type="button" class="close" mat-button aria-label="Close" data-dismiss="modal" (click)="hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div> -->
    </div>
</div>
<!-- Modal   -->
   <!-- bradcam_area_start  -->
   <div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>{{hDeparment.Title}}</h3>
                    <p><a href="index.html">Home /</a> {{hDeparment.Title}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bradcam_area_end  -->


<!-- welcome_docmed_area_start -->
<div class="welcome_docmed_area">
    <div class="container">
        <div class="row">
            <!-- <div class="col-xl-6 col-lg-6">
                <iframe width="640" height="360" src="https://www.youtube.com/embed/BiJjoZyQW8Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div> -->
            <div class="">
                <div class="welcome_docmed_info">
                    <h2>{{hDeparment.Title}}</h2>
                    <!-- <h3>Best Care For Your <br>
                            Good Health</h3> -->
                    <div [innerHTML]="hDeparment.Description | markdown"></div>
                </div>
            </div>
        </div>
    </div>
    <app-share-buttons></app-share-buttons>
</div>
<!-- welcome_docmed_area_end -->
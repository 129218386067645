import { DataService } from '../data.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-member-survey',
  templateUrl: './member-survey.component.html',
  styleUrls: ['./member-survey.component.css']
})
export class MemberSurveyComponent implements OnInit {

  url: SafeResourceUrl;

  host: string;
  PageHeader: any = [];
  Survey:any = [];

  constructor(private dataService: DataService, public sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    this.host = this.dataService.REST_API_SERVER;

    this.dataService.getPageHeaderImage()
    .subscribe(data => {
      this.PageHeader = data;
    });

    this.dataService.getSurvey()
      .subscribe(data => {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data['Member_Survey']);
      });
  }

}

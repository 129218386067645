<!-- footer start -->
<footer class="footer">
    <div class="footer_top">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-md-6 col-lg-4">
                    <div class="footer_widget" style="margin-top: -50px;margin-left: -15px;">
                        <div class="footer_logo">
                            <a href="#">
                                <img *ngIf="about.Logo" src="{{host}}{{ about.Company_Logo.url }}" alt="" width="40%"
                                style="margin-bottom: -57px;">
                            </a>
                        </div>
                        <div *ngFor="let gallery of galleries.Footer ">
                            <img src="{{host}}{{ gallery.url }}" alt="" width="300px">
                        </div>

                        <!-- <img src="http://185.201.9.174:1337{{ banner.banner.url }}" alt="" width="60px"> -->
                        <!-- <p style="text-align: justify;margin-top: -31px;">{{banner.url}}</p> -->
                        <!-- <p>
                            {{about.Small_Description}}
                        </p> -->
                        <!-- <div class="socail_links">
                            <ul>
                                <li>
                                    <a href="{{contact.Facebook}}">
                                        <i class="ti-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="{{contact.Linkedn}}">
                                        <i class="fab fa-linkedin"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="{{contact.Youtube}}">
                                        <i class="fab fa-youtube-square"></i>
                                    </a>
                                </li>
                            </ul>
                        </div> -->

                    </div>
                </div>

                <div class="col-xl-2 col-md-6 col-lg-2">
                    <div class="footer_widget">
                        <h3 class="footer_title">
                            Useful Links
                        </h3>
                        <p><li class="fa fa-chevron-right"></li><a href="owlexa/about-owlexa" style="color: white;"> About</a></p>
                        <p><li class="fa fa-chevron-right"></li><a href="owlexa/articles" style="color: white;"> Artikel</a></p>
                        <p><li class="fa fa-chevron-right"></li><a href="owlexa/contact" style="color: white;"> Contact</a></p>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 col-lg-3">
                    <div class="footer_widget">
                        <h3 class="footer_title">
                            Address
                        </h3>
                        <p><li class="fa fa-map-marker"></li> {{about.Address}}</p>
                        <p><i class="fa fa-phone" aria-hidden="true"></i> {{about.Phone}}</p>
                        <p><i class="fa fa-envelope" aria-hidden="true"></i> {{about.Sales_Email}}</p>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 col-lg-3">
                    <div class="footer_widget">
                    <h3 class="footer_title">
                        Follow Us
                    </h3>
                    <div class="socail_links">
                        <ul>
                            <li>
                                <a href="{{about.Facebook}}">
                                    <i class="fa fa-3x fa-facebook-square"></i>
                                </a>
                            </li>
                            <li>
                                <a href="{{about.Linkedin}}">
                                    <i class="fa fa-3x fa-linkedin-square"></i>
                                </a>
                            </li>
                            <li>
                                <a href="{{about.Youtube}}">
                                    <i class="fa fa-3x fa-youtube-square"></i>
                                </a>
                            </li>
                            <li>
                                <a href="{{about.Twitter}}">
                                    <i class="fa fa-3x fa-twitter-square"></i>
                                </a>
                            </li>
                            <li>
                                <a href="{{about.Instagram}}">
                                    <i class="fa fa-3x fa-instagram" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="copy-right_text">
        <div class="container">
            <div class="footer_border"></div>
            <div class="row">
                <div class="col-xl-12">
                    <p class="copy_right text-center">2021</p>
                </div>
            </div>
        </div>
    </div> -->
</footer>
<!-- footer end  -->
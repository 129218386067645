import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'owlexa-new';
  constructor(private Ttitle: Title, private meta: Meta){}
  ngOnInit(): void {
    this.Ttitle.setTitle('www.owlexa.com');
    this.meta.addTags([{
      name:'OWLEXA',
      content: 'owlexa healthcare'
    }]);
  }
}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-o-semi-managed-care',
  templateUrl: './o-semi-managed-care.component.html',
  styleUrls: ['./o-semi-managed-care.component.css']
})
export class OSemiManagedCareComponent implements OnInit {

  service: any = [];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {

    this.dataService.getManagedCare().subscribe(data =>{
      this.service = data;
    })  
  }

}

<!-- bradcam_area_start  -->
<div class="bradcam_area breadcam_bg bradcam_overlay" *ngIf="PageHeader.Contact_Us" [ngStyle]="{'background-image': 'url('+ host +''+ PageHeader.Contact_Us.url + ')'}">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>contact</h3>
                    <p><a href="/">Home /</a> contact</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bradcam_area_end  -->

<!-- ================ contact section start ================= -->
<section class="contact-section">
        <div class="container">
            <iframe width="100%" height="100%" class="absolute inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3533.8291973973573!2d106.82221042815392!3d-6.1856696513418195!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6db11bb79ead5c0!2sPT.%20Aplikanusa%20Lintasarta!5e0!3m2!1sid!2sid!4v1581323067294!5m2!1sid!2sid" loading="lazy"></iframe>

            <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1912.5010666759172!2d106.82237145053038!3d-6.1864475615011605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6db11bb79ead5c0!2sLintasarta!5e0!3m2!1sid!2sid!4v1615250896649!5m2!1sid!2sid" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
            <!-- <div class="d-none d-sm-block mb-5 pb-4">
                <iframe width="100%" height="100%" class="absolute inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3533.8291973973573!2d106.82221042815392!3d-6.1856696513418195!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6db11bb79ead5c0!2sPT.%20Aplikanusa%20Lintasarta!5e0!3m2!1sid!2sid!4v1581323067294!5m2!1sid!2sid" style="filter: grayscale(1) contrast(1.2) opacity(0.4);"></iframe>
            </div> -->


            <div class="row">
                <div class="col-12">
                    <h2 class="contact-title">Get in Touch</h2>
                </div>
                <div class="col-lg-8">
                    <!-- <form class="form-contact contact_form" (ngSubmit)="onSubmit()" method="post" id="contactForm" novalidate="novalidate">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <textarea class="form-control w-100" name="Message" id="message" cols="30" rows="9" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Message'" placeholder=" Name" [(ngModel)]="contact.Message"></textarea>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <input class="form-control valid" name="Name" id="name" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'" placeholder="Enter your name" [(ngModel)]="contact.Name">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <input class="form-control valid" name="Email" id="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" placeholder="Email" [(ngModel)]="contact.Email">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <input class="form-control" name="Subject" id="subject" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Subject'" placeholder="Enter Subject" [(ngModel)]="contact.Subject">
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <button type="submit" class="button button-contactForm boxed-btn">Send</button>
                            <a routerLink="/users" class="btn btn-link">Cancel</a>
                        </div>
                    </form> -->

                    <form class="form-contact contact_form" [formGroup]="contactForm">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <textarea class="form-control w-100" name="message" id="message" cols="30" rows="9" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Message'" placeholder="Enter your message" formControlName="message"></textarea>
                                    <small *ngIf="message.invalid && message.touched" class="text-danger">Message is Required</small>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <input class="form-control valid" name="name" id="name" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'" placeholder="Enter your name" formControlName="name">
                                    <small *ngIf="name.invalid && name.touched" class="text-danger">Name is Required</small>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <input class="form-control valid" name="email" id="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" placeholder="Enter your email" formControlName="email">
                                    <small *ngIf="email.invalid && email.touched" class="text-danger">Email is Required</small>
                                </div>
                                
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <input class="form-control" name="subject" id="subject" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Subject'" placeholder="Enter Subject" formControlName="subject">
                                    <small *ngIf="subject.invalid && subject.touched" class="text-danger">Subject is Required</small>
                                </div>
                                
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <button type="submit" class="btn btn-primary" (click)="onSubmit()" [disabled]="contactForm.invalid">Send</button>
                            <a routerLink="/users" class="btn btn-link">Cancel</a>
                        </div>
                    </form>
                </div>
                <div class="col-lg-3 offset-lg-1">
                    <div class="media contact-info">
                        <span class="contact-info__icon"><i class="ti-home"></i></span>
                        <div class="media-body">
                            <h3>{{about.Address}}</h3>
                        </div>
                    </div>
                    <div class="media contact-info">
                        <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                        <div class="media-body">
                            <h3>{{about.Phone}}</h3>
                            <p>Mon to Fri 9am to 6pm</p>
                        </div>
                    </div>
                    <div class="media contact-info">
                        <span class="contact-info__icon"><i class="ti-email"></i></span>
                        <div class="media-body">
                            <h3>{{about.Sales_Email}}</h3>
                            <p>Send us your query anytime!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!-- ================ contact section end ================= -->
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router"; 
import { DataService } from '../data.service';

@Component({
  selector: 'app-list-services',
  templateUrl: './list-services.component.html',
  styleUrls: ['./list-services.component.css']
})
export class ListServicesComponent implements OnInit {

  service: any = [];
  id: string;
  host: string;

  constructor(private dataService: DataService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.host = this.dataService.REST_API_SERVER;
    this.id = this.route.snapshot.paramMap.get("id");
    
    this.dataService.getServiceDetail(this.id).subscribe(data =>{
      this.service = data;
    });
  }

}

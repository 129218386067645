import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  chat: any = [];
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getChatNumber().subscribe( data => {
      this.chat = data;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  about: any = [];
  galleries: any = [];
  host: string;

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.host = this.dataService.REST_API_SERVER;

    this.dataService.getAboutOwlexa().subscribe(data =>{
      this.about = data;
    });


    this.dataService.getPageHeaderImage().subscribe(data =>{
      this.galleries = data;
    });
  }

}

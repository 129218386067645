
<div class="bradcam_area bradcam_overlay" *ngIf="events.Featured_Image"
    [ngStyle]="{'background-image': 'url('+ host +''+ events.Featured_Image.url + ')', 'height':'500px'}">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>Event Registration</h3>
                    <p><a href="/">Home /</a> Event Registration</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="welcome_docmed_area">
    <div>
        Registrasi {{events.Title}}
    </div>
        <div class="container">
            <iframe width="100%" frameborder="0" scrolling="no" height="950px" [src]="form" frameborder= "0" marginwidth= "0" marginheight= "0" style= "border: none; max-width:100%; max-height:100vh" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>
        </div>
    
   

        <app-share-buttons></app-share-buttons>
</div>
import { Injectable } from '@angular/core';
import { parseString } from 'xml2js';
import { setTimeout } from 'timers';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DataService {

   public REST_API_SERVER = "/oapi";
//  public REST_API_SERVER = "https://rptinhealth.owlexa.com";
  // public REST_API_SERVER = "https://10.245.245.113/content";

  constructor(private httpClient: HttpClient) { }
  
  
  public getAboutOwlexa() {
    return this.httpClient.get(this.REST_API_SERVER + '/about-owlexa');
  }

  public getContact() {
    return this.httpClient.get(this.REST_API_SERVER + '/contact');
  }

  public getArticles() {
    return this.httpClient.get(this.REST_API_SERVER + '/articles?_sort=Published_at:ASC');
  }

  public getArticleDetail(id) {
    return this.httpClient.get(this.REST_API_SERVER + '/articles/' + id);
  }

  public getCareers() {
    return this.httpClient.get(this.REST_API_SERVER + '/careers');
  }

  public getClients() {
    return this.httpClient.get(this.REST_API_SERVER + '/clients');
  }

  public getEvents() {
    return this.httpClient.get(this.REST_API_SERVER + '/events?_sort=Published_at:ASC');
  }

  public getFaqs() {
    return this.httpClient.get(this.REST_API_SERVER + '/faqs');
  }
  public getOrganizations() {
    return this.httpClient.get(this.REST_API_SERVER + '/organization-structures');
  }

  public getPromotions() {
    return this.httpClient.get(this.REST_API_SERVER + '/promotions');
  }

  public getServices() {
    return this.httpClient.get(this.REST_API_SERVER + '/services');
  }

  public getSliders() {
    return this.httpClient.get(this.REST_API_SERVER + '/home-sliders');
  }

  public getDepartmens() {
    return this.httpClient.get(this.REST_API_SERVER + '/hospital-departments');
  }

  public getNews() {
    return this.httpClient.get(this.REST_API_SERVER + '/press-release-and-news?_sort=Published_at:DESC');
  }

  public getNewsDetail(id) {
    return this.httpClient.get(this.REST_API_SERVER + '/press-release-and-news/' + id);
  }



  public getLatestNews() {
    return this.httpClient.get(this.REST_API_SERVER + '/press-release-and-news?_sort=Published_at:DESC&_limit=3');
  }

  public getLatestPromotions() {
    return this.httpClient.get(this.REST_API_SERVER + '/promotions?_sort=Published_at:DESC&_limit=3');
  }

  public getLatestEvents() {
    return this.httpClient.get(this.REST_API_SERVER + '/events?_sort=Published_at:DESC&_limit=3');
  }

  public getAdministrationServiceOnly() {
    return this.httpClient.get(this.REST_API_SERVER + '/services/1');
  }

  public getThirdPartyAdministrator() {
    return this.httpClient.get(this.REST_API_SERVER + '/services/2');
  }

  public getOptimalisasiBPJS() {
    return this.httpClient.get(this.REST_API_SERVER + '/services/3');
  }

  public getManagedCare() {
    return this.httpClient.get(this.REST_API_SERVER + '/services/5');
  }

  public getTechnologyOnly() {
    return this.httpClient.get(this.REST_API_SERVER + '/services/6');
  }

  public getPendukung() {
    return this.httpClient.get(this.REST_API_SERVER + '/services/7');
  }

  public getProvider() {
    return this.httpClient.get(this.REST_API_SERVER + '/registration-providers');
  }

  public getFaq() {
    return this.httpClient.get(this.REST_API_SERVER + '/faqs');
  }

  public getEventDetail(id) {
    return this.httpClient.get(this.REST_API_SERVER + '/events/' + id);
  }

  public getPromoDetail(id) {
    return this.httpClient.get(this.REST_API_SERVER + '/promotions/' + id);
  }

  public getCareerDetail(id) {
    return this.httpClient.get(this.REST_API_SERVER + '/careers/' + id);
  }

  public getHospitalDepartment(id) {
    return this.httpClient.get(this.REST_API_SERVER + '/hospital-departments/' + id);
  }

  public postProvider(data) {

    return this.httpClient.post(this.REST_API_SERVER + '/provider-registration-setting', data);
  }

  public postJob(data) {

    return this.httpClient.post(this.REST_API_SERVER + '/provider-registration-setting', data);
  }

  public getFooterGalery() {
    return this.httpClient.get(this.REST_API_SERVER + '/footer-gallery');
  }

  public getBannerPromo() {
    return this.httpClient.get(this.REST_API_SERVER + '/home-banner');
  }

  public getBankName() {
    return this.httpClient.get(this.REST_API_SERVER + '/bank-names');
  }

  public getServiceDetail(id) {
    return this.httpClient.get(this.REST_API_SERVER + '/services/' + id);
  }

  public getHowtoMember() {
    return this.httpClient.get(this.REST_API_SERVER + '/howto-member');
  }

  public getPageHeaderImage() {
    return this.httpClient.get(this.REST_API_SERVER + '/header-page-image');
  }

  public getSurvey() {
    return this.httpClient.get(this.REST_API_SERVER + '/survey-link-setting');
  }

  public postContact(data) {

    return this.httpClient.post(this.REST_API_SERVER + '/about-owlexa', data);
  }

  public getRegisFormSetting() {

    return this.httpClient.get(this.REST_API_SERVER + '/provider-regis-form-setting');
  }

  public getChatNumber() {

    return this.httpClient.get(this.REST_API_SERVER + '/whatsapp-chat-setting');
  }
  public getDirectLogin() {
    return this.httpClient.get(this.REST_API_SERVER + '/direct-login');
  }



  public getAllProviderList(params) {
    // var url = '/proxy/assets/owlexa_api/getAllProviderList.php';
    // var url = 'http://10.245.245.113/assets/owlexa_api/getAllProviderList.php';
    // var url = '/assets/owlexa_api/getAllProviderList.php';
    const url = `/assets/owlexa_api/getAllProviderList.php`;

    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      });

    // let data = {'length':300};
    let data = params;

    return this.httpClient.post(url, JSON.stringify(data), { headers: headers, responseType: 'text' })

  }

  public getNearbyProvider(latLong) {
    // var url = '/proxy/assets/owlexa_api/getAllProviderList.php';
    // var url = 'http://10.245.245.113:8080/assets/owlexa_api/getNearbyProvider.php';
    var url = `/assets/owlexa_api/getNearbyProvider.php`;

    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      });


    let data = {'latitude':latLong[0], 'longitude':latLong[1]};

    return this.httpClient.post(url, JSON.stringify(data), { headers: headers, responseType: 'text' })

  }

  public getAllProviderList2(params) {
    var searchBy = params['searchValue'] != null ? 'providerName' : ''
    var pageLength = 300
    var token = '8de00b671e1810e32bc4923ba3b38840'
    const postedData = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:web="http://webservice.cms.ametis.com" xmlns:dto="http://dto.cms.ametis.com">' +
      '<soapenv:Header/>' +
      '<soapenv:Body>' +
      '<web:getAllProviderList>' +
      '<web:in0>' +
      '<dto:asc>true</dto:asc>' +
      '<dto:orderBy></dto:orderBy>' +
      '<dto:pageLength>'+ pageLength +'</dto:pageLength>' +
      '<dto:searchBy>' + searchBy + '</dto:searchBy>' +
      '<dto:searchValue>'+ params['searchValue'] +'</dto:searchValue>' +
      '<dto:start>0</dto:start>' +
      '<dto:token>' + token + '</dto:token>' +
      '<dto:userId></dto:userId>' +
      '</web:in0>' +
      '</web:getAllProviderList>' +
      '</soapenv:Body>' +
      '</soapenv:Envelope>';

    const headers = new HttpHeaders(
      {
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Origin': '*'
      });


    headers.append('Content-Type', 'text/xml');
    headers.append('Accept', 'text/xml');

    return this.httpClient.post('/proxy/mobile/services/ProviderWebService', postedData, { headers, responseType: 'text' });
    // return this.httpClient.post('https://test.owlexa.com/OWLMOB/services/ProviderWebService?wsdl', postedData, { headers, responseType: 'text' });
  }

  public getNearbyProvider2(params) {
    var searchBy = params['searchValue'] != null ? 'providerName' : ''
    var pageLength = 300
    var token = '8de00b671e1810e32bc4923ba3b38840'
    var latitude = params[0]
    var longitude = params[1]
    console.log(latitude);
    const postedData = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:web="http://webservice.cms.ametis.com" xmlns:dto="http://dto.cms.ametis.com">' +
    '<soapenv:Header/>' +
    '<soapenv:Body>' +
    '<web:getNearbyProvider>' +
    '<web:in0>'+ latitude +'</web:in0>' +
    '<web:in1>'+ longitude +'</web:in1>' +
    '<web:in2>3</web:in2>' +
    '<web:in3>' +
        '<dto:asc>false</dto:asc>' +
        '<dto:orderBy></dto:orderBy>' +
        '<dto:pageLength>'+ pageLength +'</dto:pageLength>' +
        '<dto:searchBy></dto:searchBy>' +
        '<dto:searchValue></dto:searchValue>' +
        '<dto:start>0</dto:start>' +
        '<dto:token>'+ token +'</dto:token>' +
        '<dto:userId></dto:userId>' +
    '</web:in3>' +
    '</web:getNearbyProvider>' +
    '</soapenv:Body>' +
    '</soapenv:Envelope>';

    const headers = new HttpHeaders(
      {
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Origin': '*'
      });


    headers.append('Content-Type', 'text/xml');
    headers.append('Accept', 'text/xml');

    return this.httpClient.post('/proxy/mobile/services/ProviderWebService', postedData, { headers, responseType: 'text' });
    // return this.httpClient.post('https://test.owlexa.com/OWLMOB/services/ProviderWebService?wsdl', postedData, { headers, responseType: 'text' });
    
  }

}





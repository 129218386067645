import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../data.service';
import { Subject } from 'rxjs';
import { parseString } from 'xml2js';
// import { setTimeout } from 'timers';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.css']
})
export class ProvidersComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  providers: any = [];
  getAllProviderList: any = [];
  host: string;
  PageHeader: any = [];


  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private dataService: DataService, public httpClient: HttpClient) { }

  @ViewChild("search") searchView: ElementRef;

  ngOnInit(): void {
    this.host = this.dataService.REST_API_SERVER;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      paging: false,
      ordering: false,
      info: false
    };
    this.dataService.getProvider()
      .subscribe(data => {
        this.providers = data;
      });

    this.dataService.getPageHeaderImage()
      .subscribe(data => {
        this.PageHeader = data;
      });

    var params = {
      'length': 100
    };
    this.dataService.getAllProviderList2(params)
      .subscribe(data => {

        parseString(data, { explicitArray: false }, (error, result) => {
          if (error) {
            throw new Error(error);
          } else {
            var obj = result['soap:Envelope']['soap:Body']['ns1:getAllProviderListResponse']['ns1:out']['resultCollection']['xsd:anyType']
            var data = []

            if (obj.length != null) {
              obj.forEach(childObj => {
                this.getAllProviderList.push({
                  'addressprovinsi': childObj.address['_'],
                  'city': childObj.city['_'],
                  'country': childObj.country['_'],
                  'deletedStatus': childObj.deletedStatus['_'],
                  'faximile': childObj.faximile['_'],
                  'latitude': childObj.latitude['_'],
                  'longitude': childObj.longitude['_'],
                  'providerCategory': childObj.providerCategory['_'],
                  'providerCode': childObj.providerCode['_'],
                  'providerId': childObj.providerId['_'],
                  'providerName': childObj.providerName['_'],
                  'province': childObj.province['_'],
                  'status': childObj.status['_'],
                  'telephone': childObj.telephone['_']
                })
              });

            } else {
              var childObj = obj
              this.getAllProviderList.push({
                'addressprovinsi': childObj.address['_'],
                'city': childObj.city['_'],
                'country': childObj.country['_'],
                'deletedStatus': childObj.deletedStatus['_'],
                'faximile': childObj.faximile['_'],
                'latitude': childObj.latitude['_'],
                'longitude': childObj.longitude['_'],
                'providerCategory': childObj.providerCategory['_'],
                'providerCode': childObj.providerCode['_'],
                'providerId': childObj.providerId['_'],
                'providerName': childObj.providerName['_'],
                'province': childObj.province['_'],
                'status': childObj.status['_'],
                'telephone': childObj.telephone['_']
              })

            }

          }
        })
        // this.dtTrigger.next();
        setTimeout(() => {
          $('#providerTb').DataTable({
            paging: false,
            searching: false
          });
        }, 3000);
      });

  }

  searchProvider() {
    this.getAllProviderList = []

    var params = {
      length: 100,
      searchValue: this.searchView.nativeElement.value
    };

    this.dataService.getAllProviderList2(params)
      .subscribe(data => {

        parseString(data, { explicitArray: false }, (error, result) => {
          if (error) {
            throw new Error(error);
          } else {
            var obj = result['soap:Envelope']['soap:Body']['ns1:getAllProviderListResponse']['ns1:out']['resultCollection']['xsd:anyType']
            // var data = []

            if (obj.length != null) {
              obj.forEach(childObj => {
                this.getAllProviderList.push({
                  'addressprovinsi': childObj.address['_'],
                  'city': childObj.city['_'],
                  'country': childObj.country['_'],
                  'deletedStatus': childObj.deletedStatus['_'],
                  'faximile': childObj.faximile['_'],
                  'latitude': childObj.latitude['_'],
                  'longitude': childObj.longitude['_'],
                  'providerCategory': childObj.providerCategory['_'],
                  'providerCode': childObj.providerCode['_'],
                  'providerId': childObj.providerId['_'],
                  'providerName': childObj.providerName['_'],
                  'province': childObj.province['_'],
                  'status': childObj.status['_'],
                  'telephone': childObj.telephone['_']
                })
              });

            } else {
              var childObj = obj
              this.getAllProviderList.push({
                'addressprovinsi': childObj.address['_'],
                'city': childObj.city['_'],
                'country': childObj.country['_'],
                'deletedStatus': childObj.deletedStatus['_'],
                'faximile': childObj.faximile['_'],
                'latitude': childObj.latitude['_'],
                'longitude': childObj.longitude['_'],
                'providerCategory': childObj.providerCategory['_'],
                'providerCode': childObj.providerCode['_'],
                'providerId': childObj.providerId['_'],
                'providerName': childObj.providerName['_'],
                'province': childObj.province['_'],
                'status': childObj.status['_'],
                'telephone': childObj.telephone['_']
              })

            }
          }
        })
        this.dtTrigger.next;
        // setTimeout(() => {
        //   $('#providerTb').DataTable({
        //     paging: false,
        //     searching: false
        //   });
        // }, 3000);
      });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router"; 
import { DataService } from '../data.service';

@Component({
  selector: 'app-hospital-department',
  templateUrl: './hospital-department.component.html',
  styleUrls: ['./hospital-department.component.css']
})
export class HospitalDepartmentComponent implements OnInit {

  hDeparment: any = [];
  id: string;

  constructor(private dataService: DataService, private route: ActivatedRoute) { }

  
  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get("id");
    
    this.dataService.getHospitalDepartment(this.id).subscribe(data =>{
      this.hDeparment = data;
    });

  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  clients: any = [];
  host: string;
  PageHeader: any = [];

  constructor(private dataService: DataService) { }


  ngOnInit(): void {
    this.dataService.getClients().subscribe( data => {
      this.clients = data;
    });

    this.host = this.dataService.REST_API_SERVER;

    this.dataService.getPageHeaderImage()
    .subscribe(data => {
      this.PageHeader = data;
    });
  }
}

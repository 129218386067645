import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Observable, forkJoin } from 'rxjs';
import { data } from 'jquery';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  about1: any = [];
  sliders: any = [];
  departmens: any = [];
  services: any = [];
  latestNews: any = [];
  contact: any = [];
  banners:any = [];
  pamflet: any = [];
  latestPromotions: any = [];
  latestEvents:any = [];
  data:any = [];

  constructor(private dataService: DataService) { }

  title = 'angulartoastr';
  showModal: boolean;
  show() {
    this.showModal = true; // Show-Hide Modal Check

  }
  //Bootstrap Modal Close event
  hide() {
    this.showModal = false;
  }

  
  host: string;
  ngOnInit(): void {

    let json1 = this.dataService.getLatestNews();
    let json2 = this.dataService.getLatestPromotions();
    let json3 = this.dataService.getLatestEvents();

    forkJoin([json1, json2, json3]).subscribe(results => {
      this.data = results;
      // console.log(results);
    });


    this.show();
    // this.host = this.dataService.REST_API_SERVER;
    this.host = "https://owlexa.com/oapi";
    this.dataService.getAboutOwlexa().subscribe(data => {
      this.about1 = data;
      
    });

    this.dataService.getSliders().subscribe(data => {
      this.sliders = data;
      
    });

    this.dataService.getServices().subscribe(data => {
      this.services = data;
    });

    this.dataService.getServices().subscribe(data => {
      this.pamflet = data;
    });

    this.dataService.getLatestNews().subscribe(data => {
      this.latestNews = data;
    });

    this.dataService.getLatestPromotions().subscribe(data => {
      this.latestPromotions = data;
    });

    this.dataService.getLatestEvents().subscribe(data => {
      this.latestEvents = data;
    });

    this.dataService.getBannerPromo().subscribe(data => {
      this.banners = data;
      
    });
  }

}

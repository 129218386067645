import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActivatedRoute } from "@angular/router"; 
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'

@Component({
  selector: 'app-event-regis',
  templateUrl: './event-regis.component.html',
  styleUrls: ['./event-regis.component.css']
})
export class EventRegisComponent implements OnInit {

  events: any = [];
  form: any = [];
  id: string;
  host:string;
  url: SafeResourceUrl;
  constructor(private dataService: DataService, public sanitizer:DomSanitizer,  private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id");
    
    this.dataService.getEventDetail(this.id).subscribe(data =>{
      this.events = data;
      this.form = this.sanitizer.bypassSecurityTrustResourceUrl(data['Form_Link']);
    });
  }

}

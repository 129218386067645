import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AboutOwlexaComponent } from './about-owlexa/about-owlexa.component';
import { ContactComponent } from './contact/contact.component';
import { HttpClientModule } from '@angular/common/http';
import { OCorporateHealthComponent } from './o-corporate-health/o-corporate-health.component';
import { OFrontendTechnologyServiceComponent } from './o-frontend-technology-service/o-frontend-technology-service.component';
import { OManagedCareComponent } from './o-managed-care/o-managed-care.component';
import { OOptimalisasiBpjsComponent } from './o-optimalisasi-bpjs/o-optimalisasi-bpjs.component';
import { OSemiManagedCareComponent } from './o-semi-managed-care/o-semi-managed-care.component';
import { OThirdPartyAdministratorComponent } from './o-third-party-administrator/o-third-party-administrator.component';
import { NewsComponent } from './news/news.component';
import { ArticlesComponent } from './articles/articles.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { ProvidersComponent } from './providers/providers.component';
import { ClientsComponent } from './clients/clients.component';
import { NewRegistrationComponent } from './new-registration/new-registration.component';
import { MarkdownModule } from "ngx-markdown";
import { ProviderSurveyComponent } from './provider-survey/provider-survey.component';
import { CorporationSurveyComponent } from './corporation-survey/corporation-survey.component';
import { MemberSurveyComponent } from './member-survey/member-survey.component';
import { ArticleDetailComponent } from './article-detail/article-detail.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { EventsComponent } from './events/events.component';
import { CareersComponent } from './careers/careers.component';
import { FaqComponent } from './faq/faq.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { CareerDetailComponent } from './career-detail/career-detail.component';
import { DataTablesModule } from "angular-datatables";
import { PromoDetailComponent } from './promo-detail/promo-detail.component';
import { HospitalDepartmentComponent } from './hospital-department/hospital-department.component';
import { ChatComponent } from './chat/chat.component';
import { ListServicesComponent } from './list-services/list-services.component';
import { GeolocationComponent } from './geolocation/geolocation.component';
import { HowtoMemberComponent } from './howto-member/howto-member.component';
import { ApplyJobFormComponent } from './apply-job-form/apply-job-form.component';
import { EventRegisComponent } from './event-regis/event-regis.component';
import { ShareButtonsComponent } from './share-buttons/share-buttons.component';
import { FieldErrorDisplayComponent } from './field-error-display/field-error-display.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    NavbarComponent,
    AboutOwlexaComponent,
    ContactComponent,
    OCorporateHealthComponent,
    OFrontendTechnologyServiceComponent,
    OManagedCareComponent,
    OOptimalisasiBpjsComponent,
    OSemiManagedCareComponent,
    OThirdPartyAdministratorComponent,
    NewsComponent,
    ArticlesComponent,
    OrganizationsComponent,
    PromotionsComponent,
    ProvidersComponent,
    ClientsComponent,
    NewRegistrationComponent,
    ProviderSurveyComponent,
    CorporationSurveyComponent,
    MemberSurveyComponent,
    ArticleDetailComponent,
    NewsDetailComponent,
    EventsComponent,
    CareersComponent,
    FaqComponent,
    EventDetailComponent,
    CareerDetailComponent,
    PromoDetailComponent,
    HospitalDepartmentComponent,
    ChatComponent,
    ListServicesComponent,
    GeolocationComponent,
    HowtoMemberComponent,
    ApplyJobFormComponent,
    EventRegisComponent,
    ShareButtonsComponent,
    FieldErrorDisplayComponent
  ],
  imports: [
    MarkdownModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    DataTablesModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

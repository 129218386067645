<div class="bradcam_area  bradcam_overlay" *ngIf="PageHeader.Survey" [ngStyle]="{'background-image': 'url('+ host +''+ PageHeader.Survey.url + ')'}">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>Survey Korporasi</h3>
                    <p><a href="/">Home /</a> Survey Korporasi</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bradcam_area_end  -->
<div class="welcome_docmed_area">
    <div class="container">
        <iframe width="100%" frameborder="0" scrolling="no" height="950px" [src]="url" frameborder= "0" marginwidth= "0" marginheight= "0" style= "border: none; max-width:100%; max-height:100vh" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>
    </div>

</div>
// import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-provider-survey',
  templateUrl: './provider-survey.component.html',
  styleUrls: ['./provider-survey.component.css']
})
export class ProviderSurveyComponent implements OnInit {

  host: string;
  PageHeader: any = [];
  url: SafeResourceUrl;

  constructor(private dataService: DataService, public sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    this.host = this.dataService.REST_API_SERVER;

    this.dataService.getPageHeaderImage()
    .subscribe(data => {
      this.PageHeader = data;
    });

    this.dataService.getSurvey()
      .subscribe(data => {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data['Provider_Survey']);
      });
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router"; 
import { DataService } from '../data.service';


@Component({
  selector: 'app-promo-detail',
  templateUrl: './promo-detail.component.html',
  styleUrls: ['./promo-detail.component.css']
})
export class PromoDetailComponent implements OnInit {

  promotions: any = [];
  id: string;
  host: string;
  PageHeader: any = [];

  constructor(private dataService: DataService, private route: ActivatedRoute) { }

  
  ngOnInit() {
    this.host = this.dataService.REST_API_SERVER;

    this.id = this.route.snapshot.paramMap.get("id");
    
    this.dataService.getPromoDetail(this.id).subscribe(data =>{
      this.promotions = data;
    });

    this.dataService.getPageHeaderImage()
    .subscribe(data => {
      this.PageHeader = data;
    });

  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {

  articles: any = [];
  host:string;
  PageHeader: any = [];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getArticles().subscribe( data => {
      this.articles = data;
    });
    this.host = this.dataService.REST_API_SERVER;

    this.dataService.getPageHeaderImage()
    .subscribe(data => {
      this.PageHeader = data;
    });
  }

}

<!-- bradcam_area_start  -->
<div class="bradcam_area breadcam_bg bradcam_overlay"
    [ngStyle]="{'background-image': 'url('+ host +''+ PageHeader.Careers.url + ')'}">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>Karir</h3>
                    <p><a href="/">Home /</a> Karir</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bradcam_area_end  -->
<div class="container mt-5" style="margin-bottom:40">
    <div class="row mt-2 g-1">

        <div class="col-md-6" *ngFor="let career of careers" style="margin-bottom: 20px;">
            <div class="card p-2" style="height: 100%;">
                <div class="text-right"> <small>Full Time</small> </div>

                <div class="text-center mt-2 p-3">
                    <!-- <div *ngIf="career.Featured_Image">
                        <img src="{{host}}{{ career.Featured_Image.url }}" width="60" /> 
                    </div> -->
                    <span class="d-block font-weight-bold">{{career.Title}}</span>
                    <hr> <span>{{career.Placement}}</span>
                    <div class="d-flex flex-row align-items-center justify-content-center"> <i
                            class="fa fa-map-marker"></i> <small class="ml-1">{{career.Placement}}</small> </div>
                    <div class="d-flex justify-content-between mt-3"> <span>Rp. {{career.Min_Salary}}</span> <a
                            href="/owlexa/careers/{{career.id}}"><button
                                class="btn btn-sm btn-outline-dark">Detail</button></a> </div>
                </div>
            </div>
        </div>

    </div>
</div>
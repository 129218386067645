import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-howto-member',
  templateUrl: './howto-member.component.html',
  styleUrls: ['./howto-member.component.css']
})
export class HowtoMemberComponent implements OnInit {

  howto: any = [];
  host: string;

  constructor(private dataService: DataService) { }

  
  ngOnInit() {
    this.host = this.dataService.REST_API_SERVER;
    
    this.dataService.getHowtoMember().subscribe(data =>{
      this.howto = data;
    });

  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.css']
})
export class PromotionsComponent implements OnInit {

  promotions: any = [];
  host: string;
  PageHeader: any = [];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getPromotions().subscribe( data => {
      this.promotions = data;
    });

    this.host = this.dataService.REST_API_SERVER;

    this.dataService.getPageHeaderImage()
    .subscribe(data => {
      this.PageHeader = data;
    });
  }
}

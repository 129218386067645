
<!-- bradcam_area_start  -->
<div class="bradcam_area breadcam_bg bradcam_overlay" *ngIf="PageHeader.Promotions" [ngStyle]="{'background-image': 'url('+ host +''+ PageHeader.Promotions.url + ')'}">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>Promo</h3>
                    <p><a href="/">Home /</a> Promo</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bradcam_area_end  -->

<div class="welcome_docmed_area">
    <div class="container">
	<div class="row">
		<div class="col-md-12">
		    <div class="ptb-50">
		        <h2 class="text-center">Service Promo</h2>
		    </div>
		</div>
    </div>
    <br>
	<div class="row">
	    <div class="card-deck">
            <div class="card" *ngFor="let promotion of promotions">
              <!-- <img src="http://mtechwebsoft.com/templates/himax/demo/images/what_we_do/2.png" alt="" class="img-responsive"> -->
              <div class="card-body">
                <h4 class="what_we_do_title"><a href="owlexa/promotions/{{promotion.id}}">{{promotion.Title}}</a></h4>
                <p class="content multi_lines_text" [innerHTML]="promotion.Content"></p>
              </div>
            </div>
          </div>
					 
	</div>
</div>
</div>


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DataService } from '../data.service';
function ChangeImgSrcWithoutId(host) {
  var arrImg = document.images;
  for (var i = 1; i < (arrImg.length - 3); i++) {

    if (arrImg[i].getAttribute("Src") != undefined) {
      var str = arrImg[i].getAttribute("Src");
      var res = str.charAt(0)
      var split = (res != '/' ? '/' : '');

      arrImg[i].src = host + split + arrImg[i].getAttribute("Src");
      arrImg[i].style.width = '100%';
      arrImg[i].style.height = '200px';
    }
  }
}
@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.css']
})
export class ArticleDetailComponent implements OnInit {

  articles: any = [];
  id: string;
  host: string;
  PageHeader: any = [];

  constructor(private dataService: DataService, private route: ActivatedRoute) { }

  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get("id");

    this.dataService.getArticleDetail(this.id).subscribe(data => {
      this.articles = data;
    });

    this.host = this.dataService.REST_API_SERVER;

    this.dataService.getPageHeaderImage()
    .subscribe(data => {
      this.PageHeader = data;
    });

    // setTimeout(() => {
    //   ChangeImgSrcWithoutId(this.host)
    // }, 3000);

  }

}

<!-- bradcam_area_start  -->
<div class="bradcam_area bradcam_overlay" *ngIf="about.Header_Image"
    [ngStyle]="{'background-image': 'url('+ host +''+ about.Header_Image.url + ')'}">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="title">
                    <br><br>
                    <h3>About Us</h3>
                    <p><a href="index.html">Home /</a> About</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bradcam_area_end  -->

<!-- welcome_docmed_area_start -->
<div class="welcome_docmed_area" style="text-align: justify;">
    <!-- bradcam_area_start  -->
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <!-- <h3>{{about.Title}}</h3> -->
                    <br>
                    <!-- <div>{{about.Description}}</div> -->
                    <div [innerHTML]="about.Description"></div>
                    <!-- <div [innerHTML]="test"></div> -->
                    <!-- <div [innerHTML]="about.Description | markdown"></div> -->
                    <!-- <p><a href="/">Home /</a> Element</p> -->
                </div>
            </div>
        </div>
    </div>
    <!-- bradcam_area_end  -->
    <div id="fb-root"></div>
    <app-share-buttons></app-share-buttons>
</div>
<!-- welcome_docmed_area_end -->
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DataService } from '../data.service';

@Component({
  selector: 'app-career-detail',
  templateUrl: './career-detail.component.html',
  styleUrls: ['./career-detail.component.css']
})
export class CareerDetailComponent implements OnInit {

  career: any = [];
  id: string;
  host: string;
  PageHeader: any = [];

  constructor(private dataService: DataService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.host = this.dataService.REST_API_SERVER;
    this.id = this.route.snapshot.paramMap.get("id");

    this.dataService.getCareerDetail(this.id).subscribe(data => {
      this.career = data;
    });

    this.dataService.getPageHeaderImage()
    .subscribe(data => {
      this.PageHeader = data;
    });

    
  }

}

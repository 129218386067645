import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-about-owlexa',
  templateUrl: './about-owlexa.component.html',
  styleUrls: ['./about-owlexa.component.css']
})

export class AboutOwlexaComponent implements OnInit {

  host: string;
  about: any = [];
  editor: SafeResourceUrl;
  test: string;
  

  constructor(private dataService: DataService, public sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    // const regex = /<oembed.+?url="https?:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})"><\/oembed>/g;
    this.host = this.dataService.REST_API_SERVER;

    this.dataService.getAboutOwlexa().subscribe(data => {
      this.about = data;
      // this.editor = this.sanitizer.bypassSecurityTrustResourceUrl(data['Description'])
      // this.test = String(this.editor).replace(regex, '<iframe>$1</iframe>');
    })


  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { parseString } from 'xml2js';
// import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
// import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
// import {GeolocationService} from '@ng-web-apis/geolocation';
// import {Subscription} from 'rxjs';
// import {take} from 'rxjs/operators';

import {GeolocationService} from '@ng-web-apis/geolocation';
declare const L: any;

@Component({
  selector: 'app-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeolocationComponent implements OnInit {
  title = 'locationApp';

  mapsArray: any = [];
  getAllNearbyProviderList: any = [];

  constructor(private dataService: DataService, private readonly geolocation$: GeolocationService) {}

  ngOnInit() {


    
    if (!navigator.geolocation) {
      console.log('location is not supported');
    }
    navigator.geolocation.getCurrentPosition((position) => {
      const coords = position.coords;
      const latLong = [coords.latitude, coords.longitude];
      // console.log(
      //   `lat: ${position.coords.latitude}, lon: ${position.coords.longitude}`
      // );
      let mymap = L.map('map').setView(latLong, 13);

      this.dataService.getNearbyProvider2(latLong).subscribe(data => {
        parseString(data, { explicitArray: false }, (error, result) => {
          if (error) {
            throw new Error(error);
          } else {
            var obj = result['soap:Envelope']['soap:Body']['ns1:getNearbyProviderResponse']['ns1:out']['resultCollection']['xsd:anyType']
            var data = []
            if (obj.length != null) {
              obj.forEach(childObj => {
                this.getAllNearbyProviderList.push({
                  'latitude': childObj.latitude['_'],
                  'longitude': childObj.longitude['_'],
                  'providerName': childObj.providerName['_'],
                })

                L.marker([childObj.latitude['_'], childObj.longitude['_']])
                .bindPopup(childObj.providerName['_'])
                // .setContent(childObj.providerName['_'])
                .addTo(mymap);
              });

            } else {
              var childObj = obj
              this.getAllNearbyProviderList.push({
                'latitude': childObj.latitude['_'],
                'longitude': childObj.longitude['_'],
                'providerName': childObj.providerName['_']
              })

            }
            

          }
        })
      });

      

      var map = L.gridLayer
        .googleMutant({
          type: "roadmap", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
        })
      .addTo(mymap);


      // let marker = L.marker(latLong).addTo(mymap);
      let popup = L.popup()
        .setLatLng(latLong)
        .setContent('Lokasi Saya')
        .openOn(mymap);
    });
  }

}
import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators} from '@angular/forms';
import { formatCurrency } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-registration',
  templateUrl: './new-registration.component.html',
  styleUrls: ['./new-registration.component.css']
})
export class NewRegistrationComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;

  bankNames: any = [];
  PageHeader: any = [];
  host: string;

  owlexaServices: Array<any> = [
    { name: 'RawatJalan', value: 'Rawat Jalan' },
    { name: 'RawatInap', value: 'Rawat Inap' },
    { name: 'Melahirkan', value: 'Melahirkan' },
    { name: 'Dental', value: 'Dental' },
    { name: 'Kacamata', value: 'Kacamata' }
  ];
  

  constructor(private dataService: DataService, private fb: FormBuilder, private router: Router) { 
  }

  ngOnInit() {
    this.host = this.dataService.REST_API_SERVER;
    this.dataService.getRegisFormSetting().subscribe(data =>{
      this.bankNames = data['List_Bank_Name'];
    });

    this.dataService.getPageHeaderImage()
    .subscribe(data => {
      this.PageHeader = data;
    });

    this.registerForm = new FormGroup({
      'name': new FormControl(null, Validators.required),
      'address': new FormControl(null, Validators.required),
      'city': new FormControl(null, Validators.required),
      'province': new FormControl(null, Validators.required),
      'country': new FormControl(null, Validators.required),
      'postalCode': new FormControl(null, Validators.required),
      'category': new FormControl(null, Validators.required),
      'bankName': new FormControl(null, Validators.required),
      'bankAccNumber': new FormControl(null, Validators.required),
      'bankAccName': new FormControl(null, Validators.required),
      'npwp': new FormControl(null, Validators.required),
      'npwp_file': new FormControl(null, Validators.required),
      'npwp_file_source': new FormControl('', [Validators.required]),
      'tdp': new FormControl(null, Validators.required),
      'tdp_file': new FormControl(null, Validators.required),
      'tdp_file_source': new FormControl('', Validators.required),
      'opsLicense': new FormControl(null, Validators.required),
      'opsLicenseFile': new FormControl(null, Validators.required),
      'opsLicenseFile_source': new FormControl('', Validators.required),
      'ratesFile': new FormControl(null, Validators.required),
      'ratesFile_source': new FormControl('', Validators.required),
      'hospitalClassAssignmentPermitFile': new FormControl(null, Validators.required),
      'hospitalClassAssignmentPermitFile_source': new FormControl('', Validators.required),
      'drugListFile': new FormControl(null, Validators.required),
      'drugListFile_source': new FormControl('', Validators.required),
      'companyProfile': new FormControl(null, Validators.required),
      'companyProfile_source': new FormControl('', Validators.required),
      'phone': new FormControl(null, Validators.required),
      'faximile': new FormControl(null, Validators.required),
      'financeName': new FormControl(null, Validators.required),
      'financePhone': new FormControl(null, Validators.required),
      'financeEmail': new FormControl(null, Validators.required),
      'marketingName': new FormControl(null, Validators.required),
      'marketingPhone': new FormControl(null, Validators.required),
      'marketingEmail': new FormControl(null, Validators.required),
      'itName': new FormControl(null, Validators.required),
      'itPhone': new FormControl(null, Validators.required),
      'itEmail': new FormControl(null, Validators.required),
      'website': new FormControl(null, Validators.required),
      'contactPerson': new FormControl(null, Validators.required),
      'longitude': new FormControl(null, Validators.required),
      'latitude': new FormControl(null, Validators.required),
      'owlexaServices': this.fb.array([], [Validators.required]),
      'vipService': new FormControl(null, Validators.required),
      'cooperationBPJS': new FormControl(null, Validators.required),
      'internet': new FormControl(null, Validators.required),
      'discount': new FormControl(null, Validators.required),
      'priceDeal': new FormControl(null, Validators.required),
      'hospitalIS': new FormControl(null, Validators.required),
      'underwrite': new FormControl(null, Validators.required),
      'digitalEclaim': new FormControl(null, Validators.required),
      'serviceOnProcess': new FormControl(null, Validators.required),
      'requestLetter': new FormControl(null, Validators.required),
      'requestLetter_source': new FormControl('', Validators.required),
    });
    
  }

  isFieldValid(field: string) {
    return !this.registerForm.get(field).valid && this.registerForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  localUrl: any[];

  onFileSelect(event: any) {
    let formAttr = event.target.getAttribute('formControlName');

  if(event.target.files && event.target.files.length) {
    const [file] = event.target.files;
    const reader = new FileReader();  
    
    if(formAttr == 'npwp_file'){
          
      reader.onload = (event: any) => {
        this.registerForm.patchValue({
          npwp_file_source : event.target.result
        });
      
      };
      reader.readAsDataURL(file);
      
    }

    else if(formAttr == 'tdp_file'){
      reader.onload = () => {
        this.registerForm.patchValue({
          tdp_file_source: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
    else if(formAttr == 'opsLicenseFile'){
      reader.onload = () => {
        this.registerForm.patchValue({
          opsLicenseFile_source: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
    else if(formAttr == 'ratesFile'){
      reader.onload = () => {
        this.registerForm.patchValue({
          ratesFile_source: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
    else if(formAttr == 'hospitalClassAssignmentPermitFile'){
      reader.onload = () => {
        this.registerForm.patchValue({
          hospitalClassAssignmentPermitFile_source: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
    else if(formAttr == 'drugListFile'){
      reader.onload = () => {
        this.registerForm.patchValue({
          drugListFile_source: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
    else if(formAttr == 'companyProfile'){
      reader.onload = () => {
        this.registerForm.patchValue({
          companyProfile_source: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
    else if(formAttr == 'requestLetter'){
      reader.onload = () => {
        this.registerForm.patchValue({
          requestLetter_source: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
    
  }

    
}


  onCbChange(event) {
    const formArray: FormArray = this.registerForm.get('owlexaServices') as FormArray;
  
    /* Selected */
    if(event.target.checked){
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else{
      // find the unselected element
      let i: number = 0;
  
      formArray.controls.forEach((ctrl: FormControl) => {
        if(ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
  
        i++;
      });
    }
  }

  onSubmit() {

    if (this.registerForm.valid) {
      const data = {
        name: this.registerForm.value['name'],
        address: this.registerForm.value['address'],
        category: this.registerForm.value['category'],
        city: this.registerForm.value['city'],
        province: this.registerForm.value['province'],
        country: this.registerForm.value['country'],
        postalCode: this.registerForm.value['postalCode'],
        bankName: this.registerForm.value['bankName'],
        bankAccNumber: this.registerForm.value['bankAccNumber'],
        bankAccName: this.registerForm.value['bankAccName'],
        npwp: this.registerForm.value['npwp'],
        npwp_file: this.registerForm.value['npwp_file_source'],
        tdp: this.registerForm.value['tdp'],
        tdp_file: this.registerForm.value['tdp_file_source'],
        opsLicense: this.registerForm.value['opsLicense'],
        opsLicenseFile: this.registerForm.value['opsLicenseFile_source'],
        ratesFile: this.registerForm.value['ratesFile_source'],
        hospitalClassAssignmentPermitFile: this.registerForm.value['hospitalClassAssignmentPermitFile_source'],
        drugListFile: this.registerForm.value['drugListFile_source'],
        companyProfile: this.registerForm.value['companyProfile_source'],
        phone: this.registerForm.value['phone'],
        faximile: this.registerForm.value['faximile'],
        financeName: this.registerForm.value['financeName'],
        financePhone: this.registerForm.value['financePhone'],
        financeEmail: this.registerForm.value['financeEmail'],
        marketingName: this.registerForm.value['marketingName'],
        marketingPhone: this.registerForm.value['marketingPhone'],
        marketingEmail: this.registerForm.value['marketingEmail'],
        itName: this.registerForm.value['itName'],
        itPhone: this.registerForm.value['itPhone'],
        itEmail: this.registerForm.value['itEmail'],
        website: this.registerForm.value['website'],
        contactPerson: this.registerForm.value['contactPerson'],
        longitude: this.registerForm.value['longitude'],
        latitude: this.registerForm.value['latitude'],
        vipService: this.registerForm.value['vipService'],
        cooperationBPJS: this.registerForm.value['cooperationBPJS'],
        internet: this.registerForm.value['internet'],
        discount: this.registerForm.value['discount'],
        priceDeal: this.registerForm.value['priceDeal'],
        hospitalIS: this.registerForm.value['hospitalIS'],
        underwrite: this.registerForm.value['underwrite'],
        digitalEclaim: this.registerForm.value['digitalEclaim'],
        serviceOnProcess: this.registerForm.value['serviceOnProcess'],
        requestLetter: this.registerForm.value['requestLetter_source'],
      };
      this.dataService.postProvider(data)
       .subscribe(data => console.log(data), error => console.log(error));
       
      alert("Terima kasih telah melakukan pendaftaran. Kami akan segera merespon")
       this.registerForm.reset();
      //  this.router.navigate(['/']);
    } else {
      this.validateAllFormFields(this.registerForm);
    }
    
  
  }


}



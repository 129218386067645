// import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-corporation-survey',
  templateUrl: './corporation-survey.component.html',
  styleUrls: ['./corporation-survey.component.css']
})
export class CorporationSurveyComponent implements OnInit {

  @Input()
  url: SafeResourceUrl;

  host: string;
  PageHeader: any = [];
  SurveyLink: any= [];

  constructor(private dataService: DataService, public sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    this.host = this.dataService.REST_API_SERVER;

    this.dataService.getPageHeaderImage()
      .subscribe(data => {
        this.PageHeader = data;
      });

    this.dataService.getSurvey()
      .subscribe(data => {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data['Corporate_Survey']);
      });
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DataService } from '../data.service';

@Component({
  selector: 'app-apply-job-form',
  templateUrl: './apply-job-form.component.html',
  styleUrls: ['./apply-job-form.component.css']
})
export class ApplyJobFormComponent implements OnInit {

  bankNames: any = [];
  PageHeader: any = [];
  host: string;

  data_applicant = {
    Name: '',
    Address: '',
    City:'',
    Province:'',
    Country:'',
    Postal_Code:'',
    
    published: false
  };
  submitted = false;

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.host = this.dataService.REST_API_SERVER;

    this.dataService.getPageHeaderImage()
    .subscribe(data => {
      this.PageHeader = data;
    });
  }

  saveJobApply() {
    const data = {
      Name: this.data_applicant.Name,
      Address: this.data_applicant.Address,
      City: this.data_applicant.City,
      Province: this.data_applicant.Province,
      Country: this.data_applicant.Country,
      Postal_Code: this.data_applicant.Postal_Code,
      
    };

    this.dataService.postJob(data)
      .subscribe(
        response => {
          this.submitted = true;
        },
        error => {
          console.log(error);
        });

    
  }

}

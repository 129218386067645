<div class="bradcam_area bradcam_overlay" *ngIf="PageHeader.Events"
    [ngStyle]="{'background-image': 'url('+ host +''+ PageHeader.Events.url + ')'}">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>Event</h3>
                    <p><a href="/">Home /</a> Event</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bradcam_area_end  -->

<div class="welcome_docmed_area">
    <div class="container">
        <h1>Events</h1>

        <div class="card mb-3" style="width: 100%;padding: 30px;" *ngFor="let event of events">
            <a href="owlexa/events/{{event.id}}">
                <div class="row g-0">
                    <div class="col-md-4">
                        <section class="date" style="
                        text-align: left;
                        vertical-align: middle;
                    ">
                            <time datetime="23th feb">
                            <h1><span><i class="fa fa-calendar"></i> {{event.Date | date :'d'}}</span><span>{{event.Date | date :'MMM'}}</span></h1>
                            </time>
                        </section>
                    </div>
                    <div class="col-md-8">
                        <section class="card-cont">
                            <h5>{{event.Title}}</h5>
                            <h5>{{event.Place}}</h5>
                            <div class="even-date">
                                <i class="fa fa-calendar"></i>
                                <time>
                                    <span> {{event.Date}}</span>
                                    <span> 08:55pm to 12:00 am</span>
                                </time>
                            </div>
                            <div class="even-info">
                                <p>
                                    <i class="fa fa-map-marker"></i> {{event.Place}}
                                </p>
                            </div>
                            <a href="owlexa/events/{{event.id}}" style="width: 150px;">
                                <button class="btn btn-primary"> REGISTRATION </button>
                            </a>
                        </section>
                    </div>
                </div>
            </a>
        </div>

        <!-- <article class="card fl-left" *ngFor="let event of events" style="width: 100%;">
            <a href="owlexa/events/{{event.id}}">
                <section class="date">
                    <time datetime="23th feb">
                        <span>{{event.Date | date :'d'}}</span><span>{{event.Date | date :'MMM'}}</span>
                    </time>
                </section>
                <section class="card-cont">
                    <small>{{event.Title}}</small>
                    <h3>{{event.Place}}</h3>
                    <div class="even-date">
                        <i class="fa fa-calendar"></i>
                        <time>
                            <span>{{event.Date}}</span>
                            <span>08:55pm to 12:00 am</span>
                        </time>
                    </div>
                    <div class="even-info">
                        <i class="fa fa-map-marker"></i>
                        <p>
                            {{event.Place}}
                        </p>
                    </div>
                    <a href="owlexa/events/{{event.id}}" style="width: 150px;">registration</a>
                </section>
            </a>
        </article> -->
    </div>
</div>
<!-- <div class="welcome_docmed_area">
    <section class="container">
        <h1>Events</h1>
        <div class="row">
            <article class="card fl-left" *ngFor="let event of events" style="width: 100%;">
                <a href="owlexa/events/{{event.id}}">
                    <section class="date">
                        <time datetime="23th feb">
                            <span>{{event.Date | date :'d'}}</span><span>{{event.Date | date :'MMM'}}</span>
                        </time>
                    </section>
                    <section class="card-cont">
                        <small>{{event.Title}}</small>
                        <h3>{{event.Place}}</h3>
                        <div class="even-date">
                            <i class="fa fa-calendar"></i>
                            <time>
                                <span>{{event.Date}}</span>
                                <span>08:55pm to 12:00 am</span>
                            </time>
                        </div>
                        <div class="even-info">
                            <i class="fa fa-map-marker"></i>
                            <p>
                                {{event.Place}}
                            </p>
                        </div>
                        <a href="owlexa/events/{{event.id}}" style="width: 150px;">registration</a>
                    </section>
                </a>
            </article>

        </div>

    </section>
</div> -->
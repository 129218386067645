<div class="bradcam_area bradcam_overlay" *ngIf="PageHeader.Provider_Registration" [ngStyle]="{'background-image': 'url('+ host +''+ PageHeader.Provider_Registration.url + ')', 'height':'500px'}">
  <div class="container">
      <div class="row">
          <div class="col-xl-12">
              <div class="bradcam_text">
                  <h3>Pendaftaran Provider</h3>
                  <p><a href="/">Home /</a> Pendaftaran Provider</p>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- bradcam_area_end  -->
<br>
<div class="container">
<div class="card" style="padding: 20px;">
  <form [formGroup]="registerForm">

    <div class="form-group row" [ngClass]="displayFieldCss('name')">
      <label for="ProviderName" class="col-sm-2 col-form-label">Provider Name</label>
      <div class="col-sm-10">
        <input formControlName="name" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Name'" placeholder="Enter your Name">
        <!-- <small *ngIf="name.invalid && name.touched" class="text-danger">Name is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('name')" errorMsg="Please inform your name"></app-field-error-display>
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('address')">
      <label for="ProviderAddress" class="col-sm-2 col-form-label">Provider Address</label>
      <div class="col-sm-10">
        <textarea formControlName="address" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Address'" placeholder="Enter your Address"></textarea>
        <!-- <small *ngIf="address.invalid && address.touched" class="text-danger">Address is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('address')" errorMsg="Address is Required"></app-field-error-display> 
      </div>
    </div>


    <div class="form-group row" [ngClass]="displayFieldCss('city')">
      <label for="ProviderCity" class="col-sm-2 col-form-label">Provider City</label>
      <div class="col-sm-10">
        <input formControlName="city" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your City'" placeholder="Enter your City">
        <!-- <small *ngIf="city.invalid && city.touched" class="text-danger">City is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('city')" errorMsg="City is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('province')">
      <label for="ProviderProvince" class="col-sm-2 col-form-label">Provider Province</label>
      <div class="col-sm-10">
        <input formControlName="province" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Province'" placeholder="Enter your Province">
        <!-- <small *ngIf="province.invalid && province.touched" class="text-danger">Province is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('province')" errorMsg="Province is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('country')">
      <label for="ProviderCountry" class="col-sm-2 col-form-label">Provider Country</label>
      <div class="col-sm-10">
        <input formControlName="country" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Country'" placeholder="Enter your Country">
        <!-- <small *ngIf="country.invalid && country.touched" class="text-danger">Country is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('country')" errorMsg="Country is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('postalCode')">
      <label for="ProviderPostalCode" class="col-sm-2 col-form-label">Provider Postal Code</label>
      <div class="col-sm-10">
        <input formControlName="postalCode" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Postal Code'" placeholder="Enter your Postal Code">
        <!-- <small *ngIf="postalCode.invalid && postalCode.touched" class="text-danger">Postal Code is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('postalCode')" errorMsg="Postal Code is Required"></app-field-error-display> 
      </div>
    </div>
    
    <div class="form-group row" [ngClass]="displayFieldCss('category')">
      <label for="ProviderCategory" class="col-sm-2 corm-label">Provider Category</label>
      <div class="col-sm-10">
        <select class="custom-select" id="category" formControlName="category">
          <option value="Dokter_Umum">Dokter Umum</option>
          <option value="Dokter_Specialist">Dokter Specialist</option>
          <option value="Dokter_Gigi_Umum">Dokter Gigi Umum</option>
          <option value="Rumah_Sakit_Klinik">Rumah Sakit Klinik</option>
          <option value="Rumah_Bersalin">Rumah Bersalin</option>
          <option value="Rumah_Sakit_Ibu_&_Anak">Rumah Sakit Ibu & Anak</option>
          <option value="Apotek">Apotek</option>
          <option value="Laboratorium">Laboratorium</option>
          <option value="Optik">Optik</option>
          <option value="Rumah Sakit Umum">Rumah Sakit Umum</option>
          <option value="Rumah Sakit Umum Daerah">Rumah Sakit Umum Daerah</option>
          <option value="APOTEK & KLINIK">APOTEK & KLINIK</option>
          <option value="RB & KLINIK">RB & KLINIK</option>
          <option value="KLINIK BEDAH">KLINIK BEDAH</option>
          <option value="RSB">RSB</option>
          <option value="RSAL">RSAL</option>
          <option value="KLINIK & LAB">KLINIK & LAB</option>
          <option value="Personal Doctor">Personal Doctor</option>
        </select>
        <!-- <small *ngIf="category.invalid && category.touched" class="text-danger">Category is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('category')" errorMsg="Category is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('bankName')">
      <label for="ProviderBankName" class="col-sm-2 col-form-label">Provider Bank Name</label>
      <div class="col-sm-10">
        <select class="custom-select" id="category" formControlName="bankName">
          <option *ngFor="let item of bankNames" value="{{item.Bank_Name}}">{{item.Bank_Name}}</option>
      </select>
        <!-- <small *ngIf="bankName.invalid && bankName.touched" class="text-danger">Bank Name is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('bankName')" errorMsg="Bank Name is Required"></app-field-error-display> 
      </div>
    </div>


    <div class="form-group row" [ngClass]="displayFieldCss('bankAccNumber')">
      <label for="ProviderBankAccountNumber" class="col-sm-2 col-form-label">Provider Bank Number</label>
      <div class="col-sm-10">
        <input formControlName="bankAccNumber" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Bank Number'" placeholder="Enter your Bank Number">
        <!-- <small *ngIf="bankAccNumber.invalid && bankAccNumber.touched" class="text-danger">Bank Account Number is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('bankAccNumber')" errorMsg="Bank Account Number is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('bankAccName')">
      <label for="ProviderBankAccountName" class="col-sm-2 col-form-label">Provider Bank Account Name</label>
      <div class="col-sm-10">
        <input formControlName="bankAccName" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your  Bank Account Name'" placeholder="Enter your  Bank Account Name">
        <!-- <small *ngIf="bankAccName.invalid && bankAccName.touched" class="text-danger">Bank Account Name is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('bankAccName')" errorMsg="Bank Account Name is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('npwp')">
      <label for="ProviderNpwp" class="col-sm-2 col-form-label">NPWP Number</label>
      <div class="col-sm-10">
        <input formControlName="npwp" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your NPWP Number'" placeholder="Enter your NPWP Number">
        <!-- <small *ngIf="npwp.invalid && npwp.touched" class="text-danger">NPWP Number is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('npwp')" errorMsg="NPWP Number is Required"></app-field-error-display>

        <input formControlName="npwp_file" type="file" class="form-control" (change)="onFileSelect($event)">
        <!-- <small *ngIf="npwp_file.invalid && npwp_file.touched" class="text-danger">NPWP File is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('npwp_file')" errorMsg="NPWP File is Required"></app-field-error-display>
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('tdp')">
      <label for="ProviderTdp" class="col-sm-2 col-form-label">TDP Number</label>
      <div class="col-sm-10">
        <input formControlName="tdp" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your TDP Number'" placeholder="Enter your TDP Number">
        <!-- <small *ngIf="tdp.invalid && tdp.touched" class="text-danger">TDP Number is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('tdp')" errorMsg="TDP Number is Required"></app-field-error-display>

        <input formControlName="tdp_file" type="file" class="form-control" (change)="onFileSelect($event)">
        <!-- <small *ngIf="tdp_file.invalid && tdp_file.touched" class="text-danger">TDP File is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('tdp_file')" errorMsg="TDP File is Required"></app-field-error-display>
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('opsLicense')">
      <label for="ProviderOperationalLicense" class="col-sm-2 col-form-label">Surat Izin Operasional</label>
      <div class="col-sm-10">
        <input formControlName="opsLicense" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Operational License'" placeholder="Enter your Operational License">
        <!-- <small *ngIf="opsLicense.invalid && opsLicense.touched" class="text-danger">Nomor Izin Operasional is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('opsLicense')" errorMsg="Nomor Izin Operasional is Required"></app-field-error-display>

        <input formControlName="opsLicenseFile" type="file" class="form-control" (change)="onFileSelect($event)">
        <!-- <small *ngIf="opsLicenseFile.invalid && opsLicenseFile.touched" class="text-danger">Surat Izin Operasional File is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('opsLicenseFile')" errorMsg="Surat Izin Operasional File is Required"></app-field-error-display>
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('ratesFile')">
      <label for="ProviderRatesFile" class="col-sm-2 col-form-label">Softcopy Tarif</label>
      <div class="col-sm-10">
        <input formControlName="ratesFile" type="file" class="form-control" (change)="onFileSelect($event)" >
        <!-- <small *ngIf="ratesFile.invalid && ratesFile.touched" class="text-danger">Softcopy Tarif File is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('ratesFile')" errorMsg="Softcopy Tarif File is Required"></app-field-error-display>
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('hospitalClassAssignmentPermitFile')">
      <label for="ProviderPermitFile" class="col-sm-2 col-form-label">Surat Izin Penetapan Kelas RS</label>
      <div class="col-sm-10">
        <input formControlName="hospitalClassAssignmentPermitFile" type="file" class="form-control" (change)="onFileSelect($event)">
        <!-- <small *ngIf="hospitalClassAssignmentPermitFile.invalid && hospitalClassAssignmentPermitFile.touched" class="text-danger">Surat Izin Penetapan Kelas RS Tarif is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('hospitalClassAssignmentPermitFile')" errorMsg="Surat Izin Penetapan Kelas RS Tarif is Required"></app-field-error-display>
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('drugListFile')">
      <label for="ProviderDrugList" class="col-sm-2 col-form-label">Softcopy Daftar Obat</label>
      <div class="col-sm-10">
        <input formControlName="drugListFile" type="file" class="form-control" (change)="onFileSelect($event)">
        <!-- <small *ngIf="drugListFile.invalid && drugListFile.touched" class="text-danger">Softcopy Daftar Obat is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('drugListFile')" errorMsg="Softcopy Daftar Obat is Required"></app-field-error-display>
      </div>
    </div>
  
    <div class="form-group row" [ngClass]="displayFieldCss('companyProfile')">
      <label for="ProviderComProfile" class="col-sm-2 col-form-label">Company Profile</label>
      <div class="col-sm-10">
        <input formControlName="companyProfile" type="file" class="form-control" (change)="onFileSelect($event)">
        <!-- <small *ngIf="companyProfile.invalid && companyProfile.touched" class="text-danger">Company Profile is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('companyProfile')" errorMsg="Company Profile is Required"></app-field-error-display>
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('phone')">
      <label for="ProviderPhone" class="col-sm-2 col-form-label">No. Telepon</label>
      <div class="col-sm-10">
        <input formControlName="phone" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Phone'" placeholder="Enter your Phone">
        <!-- <small *ngIf="phone.invalid && phone.touched" class="text-danger">No Telepon is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('phone')" errorMsg="No Telepon is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('faximile')">
      <label for="ProviderFaximile" class="col-sm-2 col-form-label">No. Faksimile</label>
      <div class="col-sm-10">
        <input formControlName="faximile" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Faximile'" placeholder="Enter your Faximile">
        <!-- <small *ngIf="faximile.invalid && faximile.touched" class="text-danger">No. Faksimile is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('faximile')" errorMsg="No. Faksimile is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('name')">
      <label for="ProviderFinanceInfo" class="col-sm-2 col-form-label">Nama, No Tlp & Email Finance</label>
      <div class="col-sm-10">
        <input formControlName="financeName" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Finance Name'" placeholder="Enter your Finance Name">
        <!-- <small *ngIf="financeName.invalid && financeName.touched" class="text-danger">Finance name is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('financeName')" errorMsg="Finance name is Required"></app-field-error-display>
        <input formControlName="financePhone" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Finance Phone'" placeholder="Enter your Finance Phone">
        <!-- <small *ngIf="financePhone.invalid && financePhone.touched" class="text-danger">Finance Phone is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('financePhone')" errorMsg="Finance Phone is Required"></app-field-error-display>
        <input formControlName="financeEmail" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Finance Email'" placeholder="Enter your Finance Email">
        <!-- <small *ngIf="financeEmail.invalid && financeEmail.touched" class="text-danger">Finance Email is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('financeEmail')" errorMsg="Finance Email is Required"></app-field-error-display>
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('name')">
      <label for="ProviderMarketingInfo" class="col-sm-2 col-form-label">Nama, No Tlp & Email Marketing</label>
      <div class="col-sm-10">
        <input formControlName="marketingName" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Marketing Name'" placeholder="Enter your Marketing Name">
        <!-- <small *ngIf="marketingName.invalid && marketingName.touched" class="text-danger">Finance name is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('marketingName')" errorMsg="Finance name is Required"></app-field-error-display>
        <input formControlName="marketingPhone" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Marketing Phone'" placeholder="Enter your Marketing Phone">
        <!-- <small *ngIf="marketingPhone.invalid && marketingPhone.touched" class="text-danger">Finance Phone is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('marketingPhone')" errorMsg="Finance Phone is Required"></app-field-error-display>
        <input formControlName="marketingEmail" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Marketing Email'" placeholder="Enter your Marketing Email">
        <!-- <small *ngIf="marketingEmail.invalid && marketingEmail.touched" class="text-danger">Finance Email is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('marketingEmail')" errorMsg="Finance Email is Required"></app-field-error-display>
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('name')">
      <label for="ProvideritInfo" class="col-sm-2 col-form-label">Nama, No Tlp & Email it</label>
      <div class="col-sm-10">
        <input formControlName="itName" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your it Name'" placeholder="Enter your it Name">
        <!-- <small *ngIf="itName.invalid && itName.touched" class="text-danger">Finance name is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('itName')" errorMsg="Finance name is Required"></app-field-error-display>
        <input formControlName="itPhone" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your it Phone'" placeholder="Enter your it Phone">
        <!-- <small *ngIf="itPhone.invalid && itPhone.touched" class="text-danger">Finance Phone is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('itPhone')" errorMsg="Finance Phone is Required"></app-field-error-display>
        <input formControlName="itEmail" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your it Email'" placeholder="Enter your it Email">
        <!-- <small *ngIf="itEmail.invalid && itEmail.touched" class="text-danger">Finance Email is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('itEmail')" errorMsg="Finance Email is Required"></app-field-error-display>
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('website')">
      <label for="ProviderWebsite" class="col-sm-2 col-form-label">Website</label>
      <div class="col-sm-10">
        <input formControlName="website" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Website'" placeholder="Enter your Website">
        <!-- <small *ngIf="website.invalid && website.touched" class="text-danger">Website is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('website')" errorMsg="Website is Required"></app-field-error-display> 
      </div>
    </div>
    <div class="form-group row" [ngClass]="displayFieldCss('contactPerson')">
      <label for="ProviderContactPerson" class="col-sm-2 col-form-label">Contact Person</label>
      <div class="col-sm-10">
        <input formControlName="contactPerson" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Contact Person'" placeholder="Enter your Contact Person">
        <!-- <small *ngIf="contactPerson.invalid && contactPerson.touched" class="text-danger">Contact Person is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('name')" errorMsg="Contact Person is Required"></app-field-error-display> 
      </div>
    </div>
    <div class="form-group row" [ngClass]="displayFieldCss('longitude')">
      <label for="ProviderLongitude" class="col-sm-2 col-form-label">Longitude</label>
      <div class="col-sm-10">
        <input formControlName="longitude" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Longitude'" placeholder="Enter your Longitude">
        <!-- <small *ngIf="longitude.invalid && longitude.touched" class="text-danger">Longitude is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('longitude')" errorMsg="Longitude is Required"></app-field-error-display> 
      </div>
    </div>
    <div class="form-group row" [ngClass]="displayFieldCss('name')">
      <label for="ProviderLatitude" class="col-sm-2 col-form-label">Latitude</label>
      <div class="col-sm-10">
        <input formControlName="latitude" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Latitude'" placeholder="Enter your Latitude">
        <!-- <small *ngIf="latitude.invalid && latitude.touched" class="text-danger">Latitude is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('latitude')" errorMsg="Latitude is Required"></app-field-error-display> 
      </div>
    </div> 

    <div class="form-group row" [ngClass]="displayFieldCss('name')">
      <label for="JasaLayananOwlexa" class="col-sm-2 col-form-label">Jasa Layanan Owlexa</label>
      <div class="col-sm-10">
        <div class="form-group">
          <div class="form-check">
            <div *ngFor="let item of owlexaServices; let i=index">
                <input type="checkbox" [value]="item.value" (change)="onCbChange($event)" />
                <label>{{item.name}}</label>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('vipService')">
      <label class="col-sm-2 col-form-label">Layanan VIP</label>
      <div class="col-sm-10">
        <div class="form-group">
          <div class="form-check">
            <input class="" type="radio" formControlName="vipService" value="Bersedia" name="vipService">
            <label class="">
              Ya
            </label>&nbsp;&nbsp;

            <input class="" type="radio" formControlName="vipService" value="Tidak Bersedia" name="vipService">
            <label class="">
              Tidak
            </label>&nbsp;&nbsp;
          </div>
        </div>
        <!-- <small *ngIf="vipService.invalid && vipService.touched" class="text-danger">VIP Service is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('vipService')" errorMsg="VIP Service is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('cooperationBPJS')">
      <label class="col-sm-2 col-form-label">Apakah sudah Bekerjasama dengan BPJS</label>
      <div class="col-sm-10">
        <div class="form-group">
          <div class="form-check">
            <input class="" type="radio" formControlName="cooperationBPJS" value="Ya" name="cooperationBPJS">
            <label class="">
              Ya
            </label>&nbsp;&nbsp;

            <input class="" type="radio" formControlName="cooperationBPJS" value="Tidak" name="cooperationBPJS">
            <label class="">
              Tidak
            </label>&nbsp;&nbsp;
          </div>
        </div>
        <!-- <small *ngIf="cooperationBPJS.invalid && cooperationBPJS.touched" class="text-danger">Price Deal is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('cooperationBPJS')" errorMsg="Price Deal is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('internet')">
      <label class="col-sm-2 col-form-label">Kondisi Jaringan Internet provider saat ini</label>
      <div class="col-sm-10">
        <div class="form-group">
          <div class="form-check">
            <input class="" type="radio" formControlName="internet" value="Stabil" name="internet">
            <label class="">
              Stabil
            </label>&nbsp;&nbsp;

            <input class="" type="radio" formControlName="internet" value="Tidak Stabil" name="internet">
            <label class="">
              Tidak Stabil
            </label>&nbsp;&nbsp;

            <input class="" type="radio" formControlName="internet" value="Tidak Ada Jaringan" name="internet">
            <label class="">
              Tidak Ada Jaringan
            </label>&nbsp;&nbsp;
          </div>
        </div>
        <!-- <small *ngIf="internet.invalid && internet.touched" class="text-danger">Internet Network is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('internet')" errorMsg="Internet Network is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('discount')">
      <label for="ProviderDiscount" class="col-sm-2 col-form-label">Diskon</label>
      <div class="col-sm-10">
        <input formControlName="discount" type="text" class="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Your Discount'" placeholder="Enter your Discount">
        <!-- <small *ngIf="discount.invalid && discount.touched" class="text-danger">Discount is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('discount')" errorMsg="Discount is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('priceDeal')">
      <label class="col-sm-2 col-form-label">Bersedia melakukan Kesepakatan Harga</label>
      <div class="col-sm-10">
        <div class="form-group">
          <div class="form-check">
            <input class="" type="radio" formControlName="priceDeal" value="Ya" name="priceDeal">
            <label class="">
              Ya
            </label>&nbsp;&nbsp;

            <input class="" type="radio" formControlName="priceDeal" value="Tidak" name="priceDeal">
            <label class="">
              Tidak
            </label>&nbsp;&nbsp;
          </div>
        </div>
        <!-- <small *ngIf="priceDeal.invalid && priceDeal.touched" class="text-danger">Price Deal is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('priceDeal')" errorMsg="Price Deal is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('hospitalIS')">
      <label class="col-sm-2 col-form-label">Apakah saat ini sudah memiliki Sistem Informasi Rumah Sakit (SIM RS)</label>
      <div class="col-sm-10">
        <div class="form-group">
          <div class="form-check">
            <input class="" type="radio" formControlName="hospitalIS" value="Ya" name="hospitalIS">
            <label class="">
              Ya
            </label>&nbsp;&nbsp;

            <input class="" type="radio" formControlName="hospitalIS" value="Tidak" name="hospitalIS">
            <label class="">
              Tidak
            </label>&nbsp;&nbsp;
          </div>
        </div>
        <!-- <small *ngIf="hospitalIS.invalid && hospitalIS.touched" class="text-danger">Hospital Information System is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('hospitalIS')" errorMsg="Hospital Information System is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('underwrite')">
      <label class="col-sm-2 col-form-label">Bersedia melakukan bridging penjaminan</label>
      <div class="col-sm-10">
        <div class="form-group">
          <div class="form-check">
            <input class="" type="radio" formControlName="underwrite" value="Ya" name="underwrite">
            <label class="">
              Ya
            </label>&nbsp;&nbsp;

            <input class="" type="radio" formControlName="underwrite" value="Tidak" name="underwrite">
            <label class="">
              Tidak
            </label>&nbsp;&nbsp;
          </div>
        </div>
        <!-- <small *ngIf="underwrite.invalid && underwrite.touched" class="text-danger">Bridging  Underwrite is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('underwrite')" errorMsg="Bridging  Underwrite is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('digitalEclaim')">
      <label class="col-sm-2 col-form-label">Bersedia melakukanan digital claim (E-Claim)</label>
      <div class="col-sm-10">
        <div class="form-group">
          <div class="form-check">
            <input class="" type="radio" formControlName="digitalEclaim" value="Ya" name="digitalEclaim">
            <label class="">
              Ya
            </label>&nbsp;&nbsp;

            <input class="" type="radio" formControlName="digitalEclaim" value="Tidak" name="digitalEclaim">
            <label class="">
              Tidak
            </label>&nbsp;&nbsp;
          </div>
        </div>
        <!-- <small *ngIf="digitalEclaim.invalid && digitalEclaim.touched" class="text-danger">Digital E-Claim On Process is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('digitalEclaim')" errorMsg="Digital E-Claim On Process is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('serviceOnProcess')">
      <label class="col-sm-2 col-form-label">Bersedia melakukanan pelayanan selama PKS dalam proses</label>
      <div class="col-sm-10">
        <div class="form-group">
          <div class="form-check">
            <input class="" type="radio" formControlName="serviceOnProcess" value="Ya" name="serviceOnProcess">
            <label class="">
              Ya
            </label>&nbsp;&nbsp;

            <input class="" type="radio" formControlName="serviceOnProcess" value="Tidak" name="serviceOnProcess">
            <label class="">
              Tidak
            </label>&nbsp;&nbsp;
          </div>
        </div>
        <!-- <small *ngIf="serviceOnProcess.invalid && serviceOnProcess.touched" class="text-danger">Service On Process is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('serviceOnProcess')" errorMsg="Service On Process is Required"></app-field-error-display> 
      </div>
    </div>

    <div class="form-group row" [ngClass]="displayFieldCss('requestLetter')">
      <label for="ProviderDrugList" class="col-sm-2 col-form-label">Surat Permintaan Kerjasama dengan Owlexa Healthcare</label>
      <div class="col-sm-10">
        <input formControlName="requestLetter" type="file" class="form-control" (change)="onFileSelect($event)">
        <!-- <small *ngIf="requestLetter.invalid && requestLetter.touched" class="text-danger">Surat Permintaan Kerjasama dengan Owlexa Healthcare is Required</small> -->
        <app-field-error-display [displayError]="isFieldValid('requestLetter')" errorMsg="Surat Permintaan Kerjasama dengan Owlexa Healthcare is Required"></app-field-error-display>
      </div>
    </div>


    <div class="form-group">
      <!-- <button (click)="onSubmit()" [disabled]="registerForm.invalid" type="button" class="btn btn-primary">Send message</button> -->
      <button class="btn btn-primary" (click)="onSubmit()">Submit</button>
    </div>
  </form>
</div>
</div>

<!-- Start Sample Area -->
<section class="sample-text-area" style="text-align: justify;">
  <div class="container social-content">
      <h3>Share This Post</h3>
      
      <div class="social">
          <a [routerLink]="['./']" id="share-wa" class="sharer button"><i class="fa fa-3x fa-whatsapp" style="color:#00B7D5;"></i></a>
          <a [routerLink]="['./']" id="share-fb" class="sharer button"><i class="fa fa-3x fa-facebook-square" style="color:#00B7D5;"></i></a>
          <a [routerLink]="['./']" id="share-tw" class="sharer button"><i class="fa fa-3x fa-twitter-square" style="color:#00B7D5;"></i></a>
          <a [routerLink]="['./']" id="share-li" class="sharer button"><i class="fa fa-3x fa-linkedin-square" style="color:#00B7D5;"></i></a>
          <a [routerLink]="['./']" id="share-gp" class="sharer button"><i class="fa fa-3x fa-google-plus-square" style="color:#00B7D5;"></i></a>
          <a [routerLink]="['./']" id="share-em" class="sharer button"><i class="fa fa-3x fa-envelope-square" style="color:#00B7D5;"></i></a>
      </div>
        
  </div>
</section>
<!-- End Sample Area -->
import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  about: any = [];
  services:any = [];
  directLogin:any = [];
  host: string;

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.host = this.dataService.REST_API_SERVER;

    this.dataService.getAboutOwlexa().subscribe(data =>{
      this.about = data;
    });

    this.dataService.getServices().subscribe(data => {
      this.services = data;
    });
    
    this.dataService.getDirectLogin().subscribe(data => {
      this.directLogin = data;
    });
  }

}

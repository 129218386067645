<!-- bradcam_area_start  -->
<div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>{{service.Title}}</h3>
                    <!-- <p><a href="/">Home /</a> Element</p> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bradcam_area_end  -->

<!-- Start Sample Area -->
<section class="sample-text-area" style="text-align: justify;">
    <div class="container box_1170">
        <!-- <h3 class="text-heading">Text Sample</h3> -->
        <div class="excert" [innerHTML]="service.Description | markdown"></div>
        
    </div>
    <div class="container social-content">
        <h3>Share This Post</h3>
        
        <div class="social">
            <a [routerLink]="['./']" id="share-wa" class="sharer button"><i class="fa fa-3x fa-whatsapp" style="color:#00B7D5;"></i></a>
            <a [routerLink]="['./']" id="share-fb" class="sharer button"><i class="fa fa-3x fa-facebook-square" style="color:#00B7D5;"></i></a>
            <a [routerLink]="['./']" id="share-tw" class="sharer button"><i class="fa fa-3x fa-twitter-square" style="color:#00B7D5;"></i></a>
            <a [routerLink]="['./']" id="share-li" class="sharer button"><i class="fa fa-3x fa-linkedin-square" style="color:#00B7D5;"></i></a>
            <a [routerLink]="['./']" id="share-gp" class="sharer button"><i class="fa fa-3x fa-google-plus-square" style="color:#00B7D5;"></i></a>
            <a [routerLink]="['./']" id="share-em" class="sharer button"><i class="fa fa-3x fa-envelope-square" style="color:#00B7D5;"></i></a>
        </div>
          
    </div>
</section>
<!-- End Sample Area -->


import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-o-frontend-technology-service',
  templateUrl: './o-frontend-technology-service.component.html',
  styleUrls: ['./o-frontend-technology-service.component.css']
})
export class OFrontendTechnologyServiceComponent implements OnInit {

  service: any = [];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {

    this.dataService.getTechnologyOnly().subscribe(data =>{
      this.service = data;
    })  
  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-o-corporate-health',
  templateUrl: './o-corporate-health.component.html',
  styleUrls: ['./o-corporate-health.component.css']
})
export class OCorporateHealthComponent implements OnInit {

  service: any = [];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {

    this.dataService.getAdministrationServiceOnly().subscribe(data =>{
      this.service = data;
    })  
  }
}

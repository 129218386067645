import { Component, OnInit } from '@angular/core';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../data.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  about: any = [];

  PageHeader: any = [];
  host:string;


  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.host = this.dataService.REST_API_SERVER;

    this.dataService.getPageHeaderImage()
    .subscribe(data => {
      this.PageHeader = data;
    });

    this.dataService.getAboutOwlexa().subscribe(data => {
      this.about = data;
      
    });

    this.contactForm = new FormGroup({
      'name': new FormControl(null, Validators.required),
      'email': new FormControl(null, Validators.required),
      'subject': new FormControl(null, Validators.required),
      'message': new FormControl(null, Validators.required),
    });
  }

  onSubmit() {
    
    this.submitted = true;
    const data = {
      Email: this.contactForm.value['email'],
      Name: this.contactForm.value['name'],
      Subject: this.contactForm.value['subject'],
      Message: this.contactForm.value['message'],
    };
    this.dataService.postContact(data)
      .subscribe(data => console.log(data), error => console.log(error));
    
    alert("Terima kasih Anda telah mengirim pesan kepada kami")
    this.contactForm.reset();
  }
  get name(){
    return this.contactForm.get('name');
  }
  get email(){
    return this.contactForm.get('email');
  }
  get subject(){
    return this.contactForm.get('subject');
  }
  get message(){
    return this.contactForm.get('message');
  }

}

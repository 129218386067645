<!-- bradcam_area_start  -->
<div class="bradcam_area breadcam_bg bradcam_overlay" *ngIf="PageHeader.FAQ" [ngStyle]="{'background-image': 'url('+ host +''+ PageHeader.FAQ.url + ')'}">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>FAQ</h3>
                    <p><a href="/">Home /</a> FAQ</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bradcam_area_end  -->
<div class="container">
    <div class="row">
        <div class="col-lg-12 col-sm-12" style="margin: 10px;">
            <div class="accordion">
                <div *ngFor="let faq of faqs; let index = index">
                <br>
                <div class="card">
                    <div class="card-header" id="heading{{faq.id}}">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" [attr.data-target]="'#collapse' + faq.id" aria-expanded="true" aria-controls="collapse{{faq.id}}">
                                {{faq.Question}}
                            </button>
                        </h2>
                    </div>
                
                    <div id="collapse{{faq.id}}" class="collapse" aria-labelledby="heading{{faq.id}}">
                        <div class="card-body">
                            {{faq.Answer}}
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
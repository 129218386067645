import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  faqs: any = [];
  PageHeader: any = [];
  host:string;

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.host = this.dataService.REST_API_SERVER;
    this.dataService.getFaq().subscribe( data => {
      this.faqs = data;
    });

    this.dataService.getPageHeaderImage()
    .subscribe(data => {
      this.PageHeader = data;
    });
  }

}

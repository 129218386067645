<!-- bradcam_area_start  -->
<div class="bradcam_area breadcam_bg bradcam_overlay">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>Member Login</h3>
                    <p><a href="index.html">Home /</a> Member Login</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bradcam_area_end  -->

<!-- welcome_docmed_area_start -->
<div class="welcome_docmed_area" style="text-align: justify;">
    <!-- bradcam_area_start  -->
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <img src="{{host}}{{howto.Featured_Image.url}}" alt="HowTo Member" [ngStyle]="{'width':'100%'}">
                    <br>
                    <div [innerHTML]="howto.Description"></div>
                    <!-- <p><a href="/">Home /</a> Element</p> -->
                </div>
            </div>
        </div>
    </div>
    <!-- bradcam_area_end  -->

    <app-share-buttons></app-share-buttons>
</div>
<!-- welcome_docmed_area_end -->
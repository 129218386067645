<div class="d-lg-none">
    <a href="https://api.whatsapp.com/send?phone={{chat.Phone_Sales_Marketing}}&text={{chat.Chat_Message}}"
        class="float" target="_blank" style="margin-bottom: 70px;margin-right:-28px;" data-toggle="tooltip" data-placement="left"
        title="Sales Marketing">
        <i class="fa fa-whatsapp my-float"></i>
    </a>

    <a href="https://api.whatsapp.com/send?phone={{chat.Phone_Customer_Service}}&text={{chat.Chat_Message_CS}}"
        class="float" target="_blank" style="margin-right:-28px;" data-toggle="tooltip" data-placement="left" title="Customer Care">
        <i class="fa fa-whatsapp my-float"></i>
    </a>
</div>
<div class="d-none d-lg-block">
    <a href="https://api.whatsapp.com/send?phone={{chat.Phone_Sales_Marketing}}&text={{chat.Chat_Message}}"
        class="float" target="_blank" style="margin-bottom: 70px;" data-toggle="tooltip" data-placement="left"
        title="Sales Marketing">
        <i class="fa fa-whatsapp my-float"></i>
    </a>

    <a href="https://api.whatsapp.com/send?phone={{chat.Phone_Customer_Service}}&text={{chat.Chat_Message_CS}}"
        class="float" target="_blank" data-toggle="tooltip" data-placement="left" title="Customer Care">
        <i class="fa fa-whatsapp my-float"></i>
    </a>
</div>
<!-- bradcam_area_start  -->
<div class="bradcam_area bradcam_overlay" *ngIf="service.Featured_Image"
    [ngStyle]="{'background-image': 'url('+ host +''+ service.Featured_Image.url + ')'}">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="title">
                    <br><br>
                    <h3>{{service.Title}}</h3>
                    <!-- <p><a href="/">Home /</a> Element</p> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bradcam_area_end  -->
<div class="container box_1170">
    <!-- <h3 class="text-heading">Text Sample</h3> -->

    <!-- <div *ngIf="service.Pamflet.url != ''">
        <img src="{{host}}{{service.Pamflet.url}}" alt="Image Products" height="200px" width="100%" style="margin:10px">
    </div> -->

</div>
<!-- Start Sample Area -->
<section class="sample-text-area">
    <div class="container box_1170" style="text-align: justify;">
        <!-- <h3 class="text-heading">Text Sample</h3> -->
        <!-- <div class="content" [innerHTML]="service.Description | markdown"></div> -->
        <p class="content multi_lines_text" [innerHTML]="service.Description"></p>

    </div>
    <app-share-buttons></app-share-buttons>
</section>
<!-- End Sample Area -->
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutOwlexaComponent } from './about-owlexa/about-owlexa.component';
import { ApplyJobFormComponent } from './apply-job-form/apply-job-form.component';
import { ArticleDetailComponent } from './article-detail/article-detail.component';
import { ArticlesComponent } from './articles/articles.component';
import { CareerDetailComponent } from './career-detail/career-detail.component';
import { CareersComponent } from './careers/careers.component';
import { ClientsComponent } from './clients/clients.component';
import { ContactComponent } from './contact/contact.component';
import { CorporationSurveyComponent } from './corporation-survey/corporation-survey.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { EventRegisComponent } from './event-regis/event-regis.component';
import { EventsComponent } from './events/events.component';
import { FaqComponent } from './faq/faq.component';
import { HomeComponent } from './home/home.component';
import { HospitalDepartmentComponent } from './hospital-department/hospital-department.component';
import { HowtoMemberComponent } from './howto-member/howto-member.component';
import { ListServicesComponent } from './list-services/list-services.component';
import { MemberSurveyComponent } from './member-survey/member-survey.component';
import { NewRegistrationComponent } from './new-registration/new-registration.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { NewsComponent } from './news/news.component';
import { OCorporateHealthComponent } from './o-corporate-health/o-corporate-health.component';
import { OFrontendTechnologyServiceComponent } from './o-frontend-technology-service/o-frontend-technology-service.component';
import { OManagedCareComponent } from './o-managed-care/o-managed-care.component';
import { OOptimalisasiBpjsComponent } from './o-optimalisasi-bpjs/o-optimalisasi-bpjs.component';
import { OThirdPartyAdministratorComponent } from './o-third-party-administrator/o-third-party-administrator.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { PromoDetailComponent } from './promo-detail/promo-detail.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { ProviderSurveyComponent } from './provider-survey/provider-survey.component';
import { ProvidersComponent } from './providers/providers.component';

const routes: Routes = [
  {path:'', component:HomeComponent},
  {path:'owlexa/about-owlexa', component:AboutOwlexaComponent},
  {path:'owlexa/contact', component:ContactComponent},
  {path:'owlexa/articles', component:ArticlesComponent},
  {path:'owlexa/clients', component:ClientsComponent},
  {path:'owlexa/news', component:NewsComponent},
  {path:'owlexa/o-corporate-health', component:OCorporateHealthComponent},
  {path:'owlexa/o-frontend-technology-service', component:OFrontendTechnologyServiceComponent},
  {path:'owlexa/o-managed-care', component:OManagedCareComponent},
  {path:'owlexa/o-optimalisasi-bpjs', component:OOptimalisasiBpjsComponent},
  {path:'owlexa/o-semi-managed-care', component:OManagedCareComponent},
  {path:'owlexa/o-third-party-administrator', component:OThirdPartyAdministratorComponent},
  {path:'owlexa/organizations', component:OrganizationsComponent},
  {path:'owlexa/promotions', component:PromotionsComponent},
  {path:'owlexa/providers', component:ProvidersComponent},
  {path:'owlexa/new-registration', component:NewRegistrationComponent},
  {path:'owlexa/member-survey', component:MemberSurveyComponent},
  {path:'owlexa/provider-survey', component:ProviderSurveyComponent},
  {path:'owlexa/member-survey', component:MemberSurveyComponent},
  {path:'owlexa/article/:id', component:ArticleDetailComponent},
  {path:'owlexa/news/:id', component:NewsDetailComponent},
  {path:'owlexa/events', component:EventsComponent},
  {path:'owlexa/faq', component:FaqComponent},
  {path:'owlexa/careers', component:CareersComponent},
  {path:'owlexa/events/:id', component:EventDetailComponent},
  {path:'owlexa/careers/:id', component:CareerDetailComponent},
  {path:'owlexa/corporation-survey', component:CorporationSurveyComponent},
  {path:'owlexa/promotions/:id', component:PromoDetailComponent},
  {path:'owlexa/hospital-department/:id', component:HospitalDepartmentComponent},
  {path:'owlexa/services/:id', component:ListServicesComponent},
  {path:'owlexa/login-member', component:HowtoMemberComponent},
  {path:'owlexa/apply-job', component:ApplyJobFormComponent},
  {path:'owlexa/event-regis/:id', component:EventRegisComponent},

  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

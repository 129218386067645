import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  news: any = [];
  host:string;
  PageHeader: any = [];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getNews().subscribe( data => {
      this.news = data;
    });

    this.host = this.dataService.REST_API_SERVER;

    this.dataService.getPageHeaderImage()
    .subscribe(data => {
      this.PageHeader = data;
    });
  }

}

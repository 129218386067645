import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router"; 
import { DataService } from '../data.service';


@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {

  events: any = [];
  id: string;
  host:string;
  PageHeader: any = [];

  constructor(private dataService: DataService, private route: ActivatedRoute) { }

  
  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get("id");
    
    this.dataService.getEventDetail(this.id).subscribe(data =>{
      this.events = data;
    });

    this.host = this.dataService.REST_API_SERVER;

    this.dataService.getPageHeaderImage()
    .subscribe(data => {
      this.PageHeader = data;
    });

  }
}

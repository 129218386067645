<!-- bradcam_area_start  -->
<div class="bradcam_area bradcam_overlay" *ngIf="PageHeader.Provider_List"
    [ngStyle]="{'background-image': 'url('+ host +''+ PageHeader.Provider_List.url + ')'}">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>Daftar Provider</h3>
                    <p><a href="/">Home /</a> Daftar Provider</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bradcam_area_end  -->
<div class="welcome_docmed_area">
    <div class="container">
        <div class="row">
            <div class="col-md-4" style="text-align: left;">
                <input type="text" name="search" #search class="form-control">
            </div>
            <div class="col-md-4">
                <div style="text-align:left;">
                    <button (click)="searchProvider()" class="btn btn-success">Search</button>
                </div>
                <br>
            </div>
            <div class="col-md-4" style="text-align: right;">
                <h5>Total Daftar Provider 3.800</h5>
            </div>
            <table id="providerTb" class="table table-striped table-bordered table-responsive" style="width:100%">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>City</th>
                        <th>Province</th>
                        <th>Country</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let provider of getAllProviderList">
                        <td>{{ provider.providerName }}</td>
                        <td>{{ provider.providerCategory }}</td>
                        <td>{{ provider.city }}</td>
                        <td>{{ provider.province }}</td>
                        <td>{{ provider.country }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>City</th>
                        <th>Province</th>
                        <th>Country</th>
                    </tr>
                </tfoot>
            </table>
        </div>

    </div>
</div>
<div class="bradcam_area bradcam_overlay" *ngIf="PageHeader.Job_Apply" [ngStyle]="{'background-image': 'url('+ host +''+ PageHeader.Job_Apply.url + ')', 'height':'500px'}">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>Lamaran Kerja</h3>
                    <p><a href="/">Home /</a> Lamaran Kerja</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bradcam_area_end  -->
<br>
<div class="container">
  <div class="card" style="padding: 20px;">
    <div class="submit-form">
      <div *ngIf="!submitted">
        <div class="form-group row">
            <label for="ProviderName" class="col-sm-2 col-form-label">Nama Pelamar</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="Name" placeholder="Nama" [(ngModel)]="data_applicant.Name" name="Name">
            </div>
          </div>
          <div class="form-group row">
            <label for="Address" class="col-sm-2 col-form-label">Alamat</label>
            <div class="col-sm-10">
              <textarea class="form-control" id="Address" placeholder="Alamat"  [(ngModel)]="data_applicant.Address" name="Address"></textarea>
            </div>
          </div>

           <div class="form-group row">
            <label for="City" class="col-sm-2 col-form-label">Kota</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="City" placeholder="Kota" [(ngModel)]="data_applicant.City" name="City">
              <!-- <select class="custom-select" id="city" [(ngModel)]="data_applicant.City" name="City">
                <option value="">Open this select your city</option>
                <option value="Bandung">Bandung</option>
                <option value="Jakarta">Jakarta</option>
                <option value="Yogyakarta">Yogyakarta</option>
              </select> -->
            </div>
          </div>
      
          <div class="form-group row">
            <label for="Province" class="col-sm-2 col-form-label">Propinsi</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="province" placeholder="Propinsi" [(ngModel)]="data_applicant.Province" name="province">
              <!-- <select class="custom-select" id="province" [(ngModel)]="data_applicant.Province" name="Province">
                <option value="">Open this select your province</option>
                <option value="Jawa Barat">Bandung</option>
                <option value="DKI Jakarta">DKI Jakarta</option>
                <option value="Daerah Istimewa Yogyakarta">Darah Istimewa Yogyakarta</option>
              </select> -->
            </div>
          </div>
      
          <div class="form-group row">
            <label for="Country" class="col-sm-2 col-form-label">Negara</label>

            <div class="col-sm-10">
              <input type="text" class="form-control" id="country" placeholder="Negara" [(ngModel)]="data_applicant.Country" name="country">

              <!-- <select class="custom-select" id="country" [(ngModel)]="data_applicant.Country" name="Country">
                <option value="">Open this select your country</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Singapura">Singapura</option>
              </select> -->
            </div>
          </div> 
      
          <div class="form-group row">
            <label for="PostalCode" class="col-sm-2 col-form-label">Kode Pos</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="postalCode" placeholder="Kode Pos" [(ngModel)]="data_applicant.Postal_Code" name="Postal_Code">
            </div>
          </div>

          <div class="col-sm-10">
            <input type="file" class="col-sm-3 col-form-label" id="softcopyLamaran" [(ngModel)]="data_applicant.softcopyLamaran" name="softcopyLamaran">
          </div>

          <div style="text-align:center;">
            <button (click)="saveJobApply()" class="btn btn-success">Submit</button>
          </div>

      </div>
    
      <div *ngIf="submitted">
        <h4>You submitted successfully!</h4>
      </div>
    </div>
  </div>
</div>

<app-share-buttons></app-share-buttons>
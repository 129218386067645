<!-- bradcam_area_start  -->
<div class="bradcam_area bradcam_overlay" *ngIf="PageHeader.Clients" [ngStyle]="{'background-image': 'url('+ host +''+ PageHeader.Clients.url + ')'}">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="bradcam_text">
                    <h3>Daftar Client</h3>
                    <p><a href="/">Home /</a> Klien</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bradcam_area_end  -->
<div class="welcome_docmed_area">
    <section id="clients" class="section-bg">
        <div class="container" style="text-align: center;">
            <div class="section-header">
                <h1>Our Experience</h1>
                <h1><p>Meet our happy clients</p></h1>
            </div>
            <br>
            <div class="row no-gutters clients-wrap clearfix wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
    
                <div class="col-lg-3 col-md-4 col-xs-6" *ngFor="let client of clients" style="text-align: center;">
                    <div *ngIf="client.Company_Logo" >
                        <div class="client-logo"> 
                            <img src="{{host}}{{ client.Company_Logo.url }}" class="img-fluid" alt="" 
                            style="height: 120px; width: 250px;"> 
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </section>

    <app-share-buttons></app-share-buttons>
</div>
